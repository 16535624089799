import { httpApi } from "@/services/httpApi";
import { buildQueryParams } from "@/lib/queryHelper";
class apiTDO {
  async tdo_all(options) {
    let baseUrl = `/api/v1/tdo-main`;
    const queryParams = buildQueryParams(options);

    let responseType = options.action === "download" ? "blob" : "json";
    let url = `${baseUrl}?${queryParams.toString()}`;

    return await httpApi.get(url, { responseType: responseType });
  }
  async tdo_am(options) {
    let baseUrl = `/api/v1/tdo-am`;
    const queryParams = buildQueryParams(options);

    let responseType = options.action === "download" ? "blob" : "json";
    let url = `${baseUrl}?${queryParams.toString()}`;

    return await httpApi.get(url, { responseType: responseType });
  }
  async tdo_pm(options) {
    let baseUrl = `/api/v1/tdo-pm`;
    const queryParams = buildQueryParams(options);

    let responseType = options.action === "download" ? "blob" : "json";
    let url = `${baseUrl}?${queryParams.toString()}`;

    return await httpApi.get(url, { responseType: responseType });
  }
  async packingList(options) {
    let baseUrl = `/api/v1/packing-list`;
    const queryParams = buildQueryParams(options);

    let responseType = options.action === "download" ? "blob" : "json";
    let url = `${baseUrl}?${queryParams.toString()}`;

    return await httpApi.get(url, { responseType: responseType });
  }
  async deliverySchedule(options) {
    let baseUrl = `/api/v1/delivery-schedule`;
    const queryParams = buildQueryParams(options);

    let responseType = options.action === "download" ? "blob" : "json";
    let url = `${baseUrl}?${queryParams.toString()}`;

    return await httpApi.get(url, { responseType: responseType });
  }
  async labels(options) {
    let baseUrl = `/api/v1/labels`;
    const queryParams = buildQueryParams(options);

    let responseType = options.action === "download" ? "blob" : "json";
    let url = `${baseUrl}?${queryParams.toString()}`;

    return await httpApi.get(url, { responseType: responseType });
  }
  async foodLabel(options) {
    let baseUrl = `/api/v1/food-label`;
    const queryParams = buildQueryParams(options);

    let responseType = options.action === "download" ? "blob" : "json";
    let url = `${baseUrl}?${queryParams.toString()}`;

    return await httpApi.get(url, { responseType: responseType });
  }
  async foodLabelSingle(options) {
    let baseUrl = `/api/v1/food-label-single`;
    const queryParams = buildQueryParams(options);

    let responseType = options.action === "download" ? "blob" : "json";
    let url = `${baseUrl}?${queryParams.toString()}`;

    return await httpApi.get(url, { responseType: responseType });
  }
}

export default new apiTDO();
