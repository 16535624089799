<!-- InfoCard.vue -->
<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-6 col-12 mt-4">
          <ul class="list-group">
            <li
              v-for="(item, index) in leftDetails"
              :key="index"
              class="pt-0 text-sm border-0 list-group-item ps-0"
            >
              <strong class="text-dark">{{ item.label }}:</strong><br />
              {{ item.value }}
            </li>
          </ul>
        </div>
        <div class="col-lg-6 col-12 mt-4">
          <ul class="list-group">
            <li
              v-for="(item, index) in rightDetails"
              :key="index"
              class="pt-0 text-sm border-0 list-group-item ps-0"
            >
              <strong class="text-dark">{{ item.label }}:</strong><br />
              {{ item.value }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    leftDetails: {
      type: Array,
      required: true,
    },
    rightDetails: {
      type: Array,
      required: true,
    },
  },
};
</script>
