<template>
  <info-card :left-details="leftDetails" :right-details="rightDetails" />
</template>

<script>
import InfoCard from "@/components/InfoCard.vue";

export default {
  components: {
    InfoCard,
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  computed: {
    leftDetails() {
      return [
        { label: "First name", value: this.client.first_name },
        { label: "Last name", value: this.client.last_name },
        { label: "Mobile", value: this.client.mobile_number },
        { label: "Email", value: this.client.email },
      ];
    },
    rightDetails() {
      return [
        {
          label: "Account manager",
          value: this.client["account_manager.name"],
        },
        { label: "Nutritionist", value: this.client["nutritionist.name"] },
      ];
    },
  },
};
</script>
