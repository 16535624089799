<template>
  <div class="mb-4 card">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex">
        <h6 class="mb-1">Invoices</h6>
      </div>
    </div>
    <div class="p-3 card-body">
      <table v-if="invoices.length > 0" class="table align-items-center mb-0 hover">
        <thead>
          <tr>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Date
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Name
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Invoice owner
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Amount
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Status
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Payment date
            </th>

            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(invoice, index) of invoices" :key="index">
            <td>
              <p class="text-sm text-secondary mb-0">
                {{ dateFormat(invoice.created_at) }}
              </p>
            </td>
            <td>
              <p class="text-sm text-secondary mb-0">
                {{ invoice.plan_name }}
              </p>
            </td>
            <td>
              <p class="text-sm text-secondary mb-0">
                {{ invoice.owner_name }}
              </p>
            </td>
            <td>
              <p class="text-sm text-secondary mb-0">
                AED {{ invoice.amount }}
              </p>
            </td>
            <td class="text-center">
              <div class="d-flex align-items-center">
                <button
                  :class="
                    'btn-md btn-icon-only btn-rounded mb-0 me-2 btn-sm d-flex align-items-center justify-content-center btn mb-0 btn-outline-' +
                    getStatusButton(invoice.status).class
                  "
                >
                  <i
                    :class="getStatusButton(invoice.status).icon"
                    aria-hidden="true"
                  ></i>
                </button>
                <span class="text-dark text-xs">{{ invoice.status }}</span>
              </div>
            </td>
            <td>
              <p class="text-sm text-secondary mb-0">
                {{ dateFormat(invoice.payment_time) }}
              </p>
            </td>

            <td class="align-middle text-center text-sm">
              <router-link
                :to="{
                  name: 'Invoice',
                  params: {
                    id: invoice.id,
                  },
                }"
                class="mx-3"
                ><i class="fa fa-eye text-secondary"></i
              ></router-link>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="!invoices.length" class="d-flex">
        <div class="d-flex align-items-center text-sm">No invoices</div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiInvoices from "@/services/apiInvoices";
import { dateFormat } from "@/lib/dateHelper";
export default {
  name: "Invoices",
  props: {
    invoices: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    dateFormat,
    getStatusButton(status) {
      let statusButton = ApiInvoices.getStatusButton(status);
      return statusButton;
    },
  },
};
</script>
