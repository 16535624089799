<template>
  <div
    id="sidenav-collapse-main"
    class="collapse navbar-collapse w-auto h-auto h-100"
  >
    <ul class="navbar-nav">
      <template v-if="menuItems.length > 0">
        <li v-for="(menuItem, key) in menuItems" :key="key" class="nav-item">
          <template v-if="menuItem.subMenuItems">
            <sidenav-collapse
              v-if="showMenuItem(menuItem)"
              collapse-ref="pagesExamples"
              :nav-text="menuItem.navText"
              :class="menuItem.class"
            >
              <template #icon>
                <i class="text-sm" :class="menuItem.iconClass"></i>
              </template>
              <template #list>
                <ul class="nav ms-4">
                  <!-- nav links -->
                  <template
                    v-for="(
                      subMenuItem, subMenuItemKey
                    ) in menuItem.subMenuItems"
                    :key="subMenuItemKey"
                  >
                    <template
                      v-if="
                        !subMenuItem.guard ||
                        $can(
                          subMenuItem.guard.action,
                          subMenuItem.guard.subject
                        )
                      "
                    >
                      <sidenav-item
                        v-if="subMenuItem.roleId"
                        :to="subMenuItem.to"
                        :mini-icon="subMenuItem.miniIcon"
                        :text="subMenuItem.text"
                        :text-class="menuItem.textClass"
                        @click="
                          $store.commit(
                            'auth/setCurrentRole',
                            subMenuItem.roleId
                          )
                        "
                      />
                      <sidenav-item
                        v-else
                        :to="subMenuItem.to"
                        :mini-icon="subMenuItem.miniIcon"
                        :text="subMenuItem.text"
                        :text-class="menuItem.textClass"
                      />
                    </template>
                  </template>
                </ul>
              </template>
            </sidenav-collapse>
          </template>
          <template v-else>
            <sidenav
              v-if="$can(menuItem.guard.action, menuItem.guard.subject)"
              :text-class="menuItem.textClass"
              :nav-text="menuItem.navText"
              :to="menuItem.to"
            >
              <template #icon>
                <i class="text-sm" :class="menuItem.iconClass"></i>
              </template>
            </sidenav>
          </template>
        </li>
      </template>
    </ul>
  </div>
</template>
<script>
import Sidenav from "./Sidenav.vue";
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import ability from "@/services/ability";

export default {
  name: "SidenavList",
  components: {
    Sidenav,
    SidenavItem,
    SidenavCollapse,
  },
  data() {
    return {
      menuItems: [],
    };
  },
  mounted() {
    this.menuItems = this.getMenuItems();
    this.setMenuItemsGuard();
  },
  methods: {
    getMenuItems() {
      const defaultIconClass =
        this.$store.state.sidebarType === "bg-default" ||
        this.$store.state.darkMode
          ? "text-white"
          : "text-dark";
      let menuItems = [
        {
          navText: "Dashboard",
          class: this.getRoute() === "dashboard" ? "active" : "",
          iconClass: "fa fa-home " + defaultIconClass,
          subMenuItems: this.getDashboardSubMenuItemsBasedOnRole(),
        },
        {
          navText: "Clients",
          auth: true,
          class: this.getRoute() === "clients" ? "active" : "",
          iconClass: "ni ni-single-02 " + defaultIconClass,
          subMenuItems: [
            {
              to: { name: "Clients List" },
              miniIcon: "C",
              text: "Clients List",
            },
            {
              to: { name: "Clients Plans List" },
              miniIcon: "P",
              text: "Plans",
            },
          ],
        },
        {
          navText: "Plan Queues",
          textClass: "mt-1",
          auth: true,
          to: { name: "Plan Queues" },
          class: this.getRoute() === "plan-queues" ? "active" : "",
          iconClass: "fa fa-layer-group " + defaultIconClass,
        },
        {
          navText: "Invoices",
          textClass: "mt-1",
          auth: true,
          to: { name: "Invoices List" },
          class: this.getRoute() === "invoices" ? "active" : "",
          iconClass: "fa fa-file-invoice " + defaultIconClass,
        },
        {
          navText: "Users",
          auth: true,
          class: this.getRoute() === "users" ? "active" : "",
          iconClass: "ni ni-single-02 " + defaultIconClass,
          subMenuItems: [
            {
              to: { name: "Users List" },
              miniIcon: "U",
              text: "Users List",
            },
            {
              to: { name: "User Roles" },
              miniIcon: "R",
              text: "User Roles",
            },
            {
              to: { name: "New User" },
              miniIcon: "N",
              text: "New User",
            },
          ],
        },
        {
          navText: "Config",
          auth: true,
          guard: {
            action: "view",
            subject: "configs",
          },
          class: this.getRoute() === "config" ? "active" : "",
          iconClass: "ni ni-settings opacity-10 " + defaultIconClass,
          subMenuItems: [
            {
              to: { name: "Addons" },
              miniIcon: "A",
              text: "Addons",
            },
            {
              to: { name: "Allergens" },
              miniIcon: "A",
              text: "Allergens",
            },
            {
              to: { name: "Cycle Menus List" },
              miniIcon: "C",
              text: "Cycle Menus",
            },
            {
              to: { name: "Discounts" },
              miniIcon: "D",
              text: "Discounts",
            },
            {
              to: { name: "Food Groups" },
              miniIcon: "F",
              text: "Food Groups",
            },
            {
              to: { name: "Ingredients" },
              miniIcon: "I",
              text: "Ingredients",
            },
            {
              to: { name: "Plans" },
              miniIcon: "P",
              text: "Plans",
            },
            {
              to: { name: "Recipes" },
              miniIcon: "R",
              text: "Recipes",
            },
            {
              to: { name: "Substitute Menus List" },
              miniIcon: "S",
              text: "Substitute Menus",
            },
          ],
        },
        {
          navText: "Logistics",
          auth: true,
          guard: {
            action: "view",
            subject: "logistics",
          },
          class: this.getRoute() === "logistics" ? "active" : "",
          iconClass: "ni ni-delivery-fast " + defaultIconClass,
          subMenuItems: [
            {
              to: { name: "Bag Movements" },
              miniIcon: "B",
              text: "Bag Movements",
            },
            {
              to: { name: "Bag Movement Check-in" },
              miniIcon: "I",
              text: "Bag checkin",
            },
            {
              to: { name: "Bag Movement Check-out" },
              miniIcon: "O",
              text: "Bag check-out",
            },
            {
              to: { name: "Bag Collection Requests List" },
              miniIcon: "BC",
              text: "Bag Collection Requests",
            },
            {
              to: { name: "Deliveries" },
              miniIcon: "D",
              text: "Deliveries",
            },
            {
              to: { name: "Delivery Bag Movements" },
              miniIcon: "DB",
              text: "Delivery Bag Movements",
            },
          ],
        },
        {
          navText: "Operations",
          auth: true,
          guard: {
            action: "view",
            subject: "operations",
          },
          class: this.getRoute() === "operations" ? "active" : "",
          iconClass: "ni ni-bullet-list-67 " + defaultIconClass,
          subMenuItems: [
            {
              to: { name: "TDO" },
              miniIcon: "T",
              text: "TDO",
            },
            {
              to: { name: "Packing List" },
              miniIcon: "P",
              text: "Packing List",
            },
            {
              to: { name: "Delivery Schedule" },
              miniIcon: "D",
              text: "Delivery Schedule",
            },
            {
              to: { name: "Labels" },
              miniIcon: "L",
              text: "Labels",
            },
            {
              to: { name: "Food Label" },
              miniIcon: "F",
              text: "Food Label",
            },
            {
              to: { name: "Single Food Label" },
              miniIcon: "SF",
              text: "Single Food Label",
            },
          ],
        },
        {
          navText: "Reports",
          auth: true,
          guard: {
            action: "view",
            subject: "reports",
          },
          class: this.getRoute() === "reports" ? "active" : "",
          iconClass: "ni ni-collection " + defaultIconClass,
          subMenuItems: [
            {
              to: { name: "Revenue Report" },
              miniIcon: "R",
              text: "Revenue Report",
            },
            {
              to: { name: "Collections Report" },
              miniIcon: "C",
              text: "Collections Report",
            },
          ],
        },
      ];
      return menuItems;
    },
    setMenuItemsGuard() {
      for (let i in this.menuItems) {
        if (this.menuItems[i].auth) {
          if (this.menuItems[i].subMenuItems) {
            for (let j in this.menuItems[i].subMenuItems) {
              let route = this.$router.resolve({
                name: this.menuItems[i].subMenuItems[j].to.name,
              });
              if (route.meta && route.meta.guard) {
                this.menuItems[i].subMenuItems[j].guard = route.meta.guard;
              }
            }
          } else {
            let route = this.$router.resolve({
              name: this.menuItems[i].to.name,
            });
            if (route.meta && route.meta.guard) {
              this.menuItems[i].guard = route.meta.guard;
            }
          }
        }
      }
    },
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    getDashboardSubMenuItemsBasedOnRole() {
      let result = [];
      if (!this.$store.state.auth.user.roles) {
        return result;
      }
      let route = "";
      for (let i in this.$store.state.auth.user.roles.data) {
        if (this.$store.state.auth.user.roles.data[i].id == 1) {
          route = "/dashboard";
        }
        if (this.$store.state.auth.user.roles.data[i].id == 2) {
          route = "/dashboard-account-admin";
        }
        if (this.$store.state.auth.user.roles.data[i].id == 3) {
          route = "/dashboard-account-manager";
        }
        if (this.$store.state.auth.user.roles.data[i].id == 4) {
          route = "/dashboard-account-supervisor";
        }
        if (this.$store.state.auth.user.roles.data[i].id == 5) {
          route = "/dashboard-nutritionist";
        }
        if (this.$store.state.auth.user.roles.data[i].id == 8) {
          route = "/dashboard-sales-agent";
        }

        if (route) {
          result.push({
            to: route,
            miniIcon: this.$store.state.auth.user.roles.data[i].name.charAt(0),
            text: this.$store.state.auth.user.roles.data[i].name,
            roleId: this.$store.state.auth.user.roles.data[i].id,
          });
        }
        route = "";
      }
      if (result.length == 0) {
        result.push({
          to: "/dashboard-default",
          miniIcon: "D",
          text: "Default",
          roleId: "",
        });
      }
      return result;
    },
    showMenuItem(menuItem) {
      if (menuItem.auth) {
        if (!this.$store.state.auth.authenticated) {
          return false;
        }
        if (
          menuItem.guard &&
          !ability.can(menuItem.guard.action, menuItem.guard.subject)
        ) {
          return false;
        }
        for (let i in menuItem.subMenuItems) {
          if (!menuItem.subMenuItems[i].guard) {
            return true;
          }
          if (
            ability.can(
              menuItem.subMenuItems[i].guard.action,
              menuItem.subMenuItems[i].guard.subject
            )
          ) {
            return true;
          }
        }
        return false;
      }
      return true;
    },
  },
};
</script>
