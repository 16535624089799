<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row user-stats">
          <div class="col-md-6 col-12" :class="midColumnClass">
            <mini-statistics-card
              title="Today's Collection"
              :value="dashboardStatistics.payment_received_today_text"
              :description="
                `Yesterday: ` +
                dashboardStatistics.payment_received_yesterday_text
              "
              :icon="{
                component: 'ni ni-money-coins',
                background: 'bg-gradient-primary',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div
            v-if="showDeliveries || showRevenue"
            class="col-md-6 col-12 col-lg-3"
          >
            <mini-statistics-card
              v-if="showDeliveries"
              title="Total deliveries"
              :value="
                dashboardStatistics.delivery_data_month_to_date_count_text
              "
              :description="
                `<span class='text-sm font-weight-bolder text-danger' >` +
                dashboardStatistics.total_delivery_text +
                `</span> deliveries today`
              "
              :icon="{
                component: 'ni ni-delivery-fast',
                background: 'bg-gradient-danger',
                shape: 'rounded-circle',
              }"
            />
            <mini-statistics-card
              v-if="showRevenue"
              title="Today's Revenue"
              :value="dashboardStatistics.delivery_data_today_revenue_text"
              :description="
                `<span class='text-sm font-weight-bolder text-danger' >` +
                dashboardStatistics.total_delivery_text +
                `</span> deliveries today`
              "
              :icon="{
                component: 'ni ni-delivery-fast',
                background: 'bg-gradient-danger',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-md-6 col-12" :class="midColumnClass">
            <mini-statistics-card
              title="Total Clients"
              :value="dashboardStatistics.total_count_text"
              :description="
                `<span class='text-sm font-weight-bolder text-danger' >` +
                dashboardStatistics.total_count_this_month_to_date_text +
                `</span> new clients this month`
              "
              :icon="{
                component: 'ni ni-paper-diploma',
                background: 'bg-gradient-success',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-md-6 col-12" :class="midColumnClass">
            <mini-statistics-card
              title="Total Collection"
              :value="dashboardStatistics.payment_received_month_to_date_text"
              :description="
                `Last Month: ` +
                dashboardStatistics.payment_received_last_month_to_date_text
              "
              :icon="{
                class: 'w-50',
                component: 'ni ni-cart',
                background: 'bg-gradient-warning',
                shape: 'rounded-circle',
              }"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div
            v-if="invoice_labels.length > 0"
            class="mb-lg"
            :class="bigColumnClass"
          >
            <gradient-line-chart
              id="invoice-chart"
              title="Collection Overview"
              description=""
              :chart="{
                labels: invoice_labels,
                datasets: invoiceDataSets,
              }"
            />
          </div>
          <div
            v-if="
              (showRevenue && revenue_labels.length > 0) ||
              (showDeliveries && delivery_labels.length > 0)
            "
            class="col-lg-6 mb-lg"
          >
            <gradient-line-chart
              v-if="showDeliveries"
              id="delivery-chart"
              title="Deliveries Overview"
              description=""
              :chart="{
                labels: delivery_labels,
                datasets: deliveryDataSets,
              }"
            />
            <gradient-line-chart
              v-if="showRevenue"
              id="revenue-chart"
              title="Revenue Overview"
              description=""
              :chart="{
                labels: revenue_labels,
                datasets: revenueDataSets,
              }"
            />
          </div>
        </div>
        <div v-if="showClientPlansExpiring" class="row mt-4">
          <div class="col-12 col-lg-12">
            <client-plans-expiring
              :headers="[
                'Name',
                'Expiry',
                'Plan',
                'Sequence',
                'Mobile',
                'COMPLETION',
                'Renew',
              ]"
              :items="client_plans_expiring"
              :card-header="'Client Plans Expiring'"
            />
          </div>
        </div>
        <div v-if="showRetentionClients" class="row mt-4">
          <div class="col-12 col-lg-12">
            <general-table
              :id="'retention_client_list'"
              :headers="['Name', 'Plan', 'Expiry Date', 'Sequence', 'Renew']"
              :items="retention_clients"
              :card-header="'Retention Clients'"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12 col-lg-12">
            <general-table
              :id="'bounce_back_client_list'"
              :headers="['Name', 'Plan', 'Expiry Date', 'Sequence', 'Renew']"
              :items="bounce_back_clients"
              :card-header="'Bounce Back Clients'"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12 col-lg-6">
            <ranking-list-card
              :horizontal-break="false"
              :card="{
                title: 'Invoices',
                date: '',
                subtitle: '',
              }"
              :item="invoices_list"
            >
            </ranking-list-card>
          </div>
          <div v-if="showRetentionBounceBack" class="col-12 col-lg-6 mt-4">
            <bounce-back-card
              :items="retention_and_bounceback_table_data"
              title="Retention and Bounceback"
            />
          </div>
          <div v-if="showBounceBack" class="col-12 col-lg-6 mt-4">
            <bounce-back-card
              :items="bounceback_table_data"
              title="Bounceback"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MiniStatisticsCard from "@/components/Cards/MiniStatisticsCard.vue";
import GradientLineChart from "@/components/GradientLineChart.vue";
import ClientPlansExpiring from "./ClientPlansExpiring.vue";
import GeneralTable from "./GeneralTable.vue";
import RankingListCard from "@/examples/Cards/RankingListCard.vue";
import API from "@/services/api";
import { showMessage } from "@/assets/js/show-message";
import { currencyFormat, handleError } from "@/lib/helpers";
import BounceBackCard from "./BounceBackCard.vue";
export default {
  name: "GeneralDashboard",
  components: {
    MiniStatisticsCard,
    GradientLineChart,
    ClientPlansExpiring,
    GeneralTable,
    RankingListCard,
    BounceBackCard,
  },
  props: {
    getDashboardData: {
      type: Function,
      required: true,
    },

    showDeliveries: {
      type: Boolean,
      default: false,
    },
    showRevenue: {
      type: Boolean,
      default: false,
    },
    layout: {
      type: String,
      default: "",
    },
    showClientPlansExpiring: {
      type: Boolean,
      default: true,
    },
    showRetentionClients: {
      type: Boolean,
      default: true,
    },
    showRetentionBounceBack: {
      type: Boolean,
      default: true,
    },
    showBounceBack: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dashboardData: {},
      dashboardStatistics: {
        payment_received_today_text: "AED 0.00",
        payment_received_today_percentage_text: "0",
        payment_received_today_percentage_class: "text-success",
        payment_received_month_to_date_percentage_class: "text-success",
        payment_received_yesterday_text: "AED 0.00",
        payment_received_month_to_date_text: "AED 0.00",
        payment_received_month_to_date_percentage_text: "0",
        payment_received_last_month_to_date_text: "AED 0.00",
        total_count_this_month_to_date_text: 0,
        total_count_text: 0,
        delivery_data_today_revenue_text: "AED 0.00",
        total_delivery_text: 0,
        delivery_data_month_to_date_count_text: 0,
      },
      revenue_labels: [],
      delivery_labels: [],
      invoice_labels: [],
      invoiceDataSets: [],
      revenueDataSets: [],
      deliveryDataSets: [],
      account_manager_data: [],
      client_plans_expiring: [],
      retention_clients: [],
      bounce_back_clients: [],
      invoices_list: [],
      retention_and_bounceback: [],
      retention_and_bounceback_table_data: [],
      bounceback_table_data: [],
    };
  },
  computed: {
    bigColumnClass() {
      return this.layout === "sales" ? "col-lg-12" : "col-lg-6";
    },
    midColumnClass() {
      return this.layout === "sales" ? "col-lg-4" : "col-lg-3";
    },
  },

  async mounted() {
    await Promise.all([
      this.setInvoiceMonthToDate(),
      this.setRevenueMonthToDate(),
      this.setDashboardData(),
    ]);
    this.setDashboardStatistics(this.dashboardData, true);
  },

  methods: {
    currencyFormat,
    async setDashboardData() {
      const response = await this.getDashboardData().catch(handleError);
      if (response.status == 200) {
        this.dashboardData = response;
      } else {
        showMessage(response.message, "error");
      }
    },
    async setRevenueMonthToDate() {
      const response = await API.getRevenueMonthToDate().catch(handleError);
      if (response.status == 200) {
        this.revenue_labels = Object.values(response.data.data).map(
          (revenue) => {
            const date = new Date(revenue.date);
            return String(date.getDate()).padStart(2, "0");
          }
        );
        this.delivery_labels = Object.values(response.data.data).map((item) => {
          const date = new Date(item.date);
          return String(date.getDate()).padStart(2, "0");
        });
        this.revenueDataSets = [
          {
            label: "Revenue",
            data: Object.values(response.data.data).map((revenue) => {
              return revenue.revenue;
            }),
          },
        ];
        this.deliveryDataSets = [
          {
            label: "Deliveries",
            data: Object.values(response.data.data).map((item) => {
              return item.count;
            }),
          },
        ];
      } else {
        showMessage(response.message, "error");
      }
    },
    async setInvoiceMonthToDate() {
      const response = await API.getInvoiceMonthToDate().catch(handleError);
      if (response.status == 200) {
        this.invoice_labels = Object.values(response.data.data).map(
          (invoice) => {
            const date = new Date(invoice.date);
            return String(date.getDate()).padStart(2, "0");
          }
        );

        const dataSeatAmount = Object.values(response.data.data).map(
          (invoice) => {
            return invoice.amount;
          }
        );

        this.invoiceDataSets = [
          {
            label: "Amount",
            data: dataSeatAmount,
          },
        ];
      } else {
        showMessage(response.message, "error");
      }
    },
    setClientPlansExpiringData(response) {
      if (!this.showClientPlansExpiring) {
        return;
      }
      const data = response.data.client_plans_expiring[0].data;
      data.forEach((element, index) => {
        const start_date = new Date(data[index]["start_date"]);
        const end_date = new Date(data[index]["actual_end_date"]);
        const current_date = new Date();
        let progress = 0;
        if (start_date > current_date) {
          progress += 0;
        } else if (current_date > end_date) {
          progress += 100;
        } else {
          const diffTime = Math.abs(
            current_date.getTime() - start_date.getTime()
          );
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          progress += (diffDays * 100) / data[index]["total_days"];
        }

        progress = Math.ceil(progress);

        this.client_plans_expiring.push({
          brand: {
            logo: data[index]["client.image"],
            name: data[index]["client.name"],
          },
          badge: { color: "success" },
          expiry: data[index]["actual_end_date"],
          plan: data[index]["planVariant.name"],
          sequence: data[index]["sequence"],
          mobileno: data[index]["client.mobile"],
          id: data[index]["client.id"],
          progress,
        });
      });
    },
    setRetentionClientsData(response) {
      if (!this.showRetentionClients) {
        return;
      }
      const data = response.data.client_plans_expired.retention_clients.data;
      data.forEach((element, index) => {
        this.retention_clients.push({
          brand: {
            logo: data[index]["client.image"],
            name: data[index]["client.name"],
            phone: data[index]["client.mobile"],
          },
          plan: data[index]["planVariant.name"],
          expiry: data[index]["actual_end_date"],
          sequence: data[index]["sequence"],
          id: data[index]["client.id"],
        });
      });
    },
    setBounceBackClientsData(data) {
      data.forEach((element, index) => {
        this.bounce_back_clients.push({
          brand: {
            logo: data[index]["client.image"],
            name: data[index]["client.name"],
            phone: data[index]["client.mobile"],
          },
          plan: data[index]["planVariant.name"],
          expiry: data[index]["actual_end_date"],
          sequence: data[index]["sequence"],
          id: data[index]["client.id"],
        });
      });
    },
    setDashboardStatistics(response) {
      if (!response.data) {
        return;
      }
      const appInstance = this;
      appInstance.dashboardStatistics.total_count_this_month_to_date_text =
        response.data.clients?.total_count_this_month_to_date || "";
      appInstance.dashboardStatistics.total_count_text =
        response.data.clients?.total_count || "Nil";
      if (this.showRevenue) {
        appInstance.dashboardStatistics.delivery_data_today_revenue_text =
          currencyFormat(response.data.delivery_data_today.revenue) || "";
        appInstance.dashboardStatistics.total_delivery_text =
          response.data.delivery_data_today.count || "0";
        appInstance.dashboardStatistics.delivery_data_month_to_date_count_text =
          response.data.delivery_data_month_to_date.count || "";
      }

      appInstance.dashboardStatistics.payment_received_today_text =
        currencyFormat(response.data.payment_received_today.total);
      appInstance.dashboardStatistics.payment_received_yesterday_text =
        currencyFormat(response.data.payment_received_yesterday.total);

      if (response.data.payment_received_yesterday.total > 0) {
        appInstance.dashboardStatistics.payment_received_today_percentage_text =
          ((response.data.payment_received_today.total -
            response.data.payment_received_yesterday.total) *
            100) /
          response.data.payment_received_yesterday.total;

        if (
          appInstance.dashboardStatistics
            .payment_received_today_percentage_text > 0
        ) {
          appInstance.dashboardStatistics.payment_received_today_percentage_text =
            "+" +
            appInstance.dashboardStatistics
              .payment_received_today_percentage_text;
        } else {
          appInstance.dashboardStatistics.payment_received_today_percentage_class =
            "text-danger";
        }
      } else {
        if (
          response.data.payment_received_yesterday.total ==
          response.data.payment_received_today.total
        ) {
          appInstance.dashboardStatistics.payment_received_today_percentage_text =
            "0";
        } else {
          appInstance.dashboardStatistics.payment_received_today_percentage_text =
            "+100";
        }
      }

      appInstance.dashboardStatistics.payment_received_month_to_date_text =
        currencyFormat(response.data.payment_received_month_to_date.total);
      appInstance.dashboardStatistics.payment_received_last_month_to_date_text =
        currencyFormat(response.data.payment_received_last_month_to_date.total);
      if (response.data.payment_received_last_month_to_date.total > 0) {
        appInstance.dashboardStatistics.payment_received_month_to_date_percentage_text =
          ((response.data.payment_received_month_to_date.total -
            response.data.payment_received_last_month_to_date.total) *
            100) /
          response.data.payment_received_last_month_to_date.total;
        if (
          appInstance.dashboardStatistics
            .payment_received_month_to_date_percentage_text > 0
        ) {
          appInstance.dashboardStatistics.payment_received_month_to_date_percentage_text =
            "+" +
            appInstance.dashboardStatistics
              .payment_received_month_to_date_percentage_text;
        } else {
          appInstance.dashboardStatistics.payment_received_month_to_date_percentage_class =
            "text-danger";
        }
      } else {
        if (
          response.data.payment_received_month_to_date.total ==
          response.data.payment_received_last_month_to_date.total
        ) {
          appInstance.dashboardStatistics.payment_received_month_to_date_percentage_text =
            "0";
        } else {
          appInstance.dashboardStatistics.payment_received_month_to_date_percentage_text =
            "+100";
        }
      }

      this.setInvoicesList(response);

      this.setBounceBackTableData(response);

      this.setRetentionBouncebackTableData(response);

      this.setBounceBackClientsData(
        response.data.client_plans_expired.bounce_back_clients.data
      );

      this.setClientPlansExpiringData(response);
      this.setRetentionClientsData(response);
    },
    setInvoicesList(response) {
      if (response.data.invoices.length > 0) {
        response.data.invoices[0].data.forEach((invoice) => {
          let ino =
            invoice.payment_status == "Pending"
              ? invoice.invoice_number
              : "#" + invoice.invoice_number;

          this.invoices_list.push({
            id: invoice.client_id,
            title: invoice.client_name,
            date:
              invoice.payment_time == null
                ? "" + ino
                : invoice.payment_time + ino,
            amount: invoice.amount,
            status: invoice.status,
            icon:
              invoice.status == "Paid"
                ? "fa-arrow-up"
                : invoice.payment_status == "Pending"
                ? "fa-arrow-down"
                : invoice.payment_status == "Cancel"
                ? "fa fa-close"
                : "fa fa-info",

            color:
              invoice.status == "Paid"
                ? "success"
                : invoice.payment_status == "Pending"
                ? "danger"
                : invoice.payment_status == "Cancel"
                ? "secondary"
                : "info",
            payment_link: invoice.payment_link,
          });
        });
      }
    },
    setRetentionBouncebackTableData(response) {
      if (!this.showRetentionBounceBack) {
        return;
      }
      this.retention_and_bounceback_table_data = [
        {
          title: "",
          current_month: "Current Month",
          previous_month: "Previous Month",
        },
        {
          title: "Retention",
          current_month:
            response.data.current_month_to_date?.retention || "Nil",
          previous_month: response.data.previous_month?.retention || "Nil",
        },
        {
          title: "Retention collection",
          current_month:
            currencyFormat(
              response.data.current_month_to_date?.retention_collection
            ) || "Nil",
          previous_month:
            currencyFormat(
              response.data.previous_month?.retention_collection
            ) || "Nil",
        },
        {
          title: "Bounce Back",
          current_month:
            response.data.current_month_to_date?.bounce_back || "Nil",
          previous_month: response.data.previous_month?.bounce_back || "Nil",
        },
        {
          title: "Bounce back collection",
          current_month: currencyFormat(
            response.data.current_month_to_date?.bounce_back_collection || "Nil"
          ),
          previous_month: currencyFormat(
            response.data.previous_month?.bounce_back_collection || "Nil"
          ),
        },
        {
          title: "Retention %",
          current_month:
            response.data.current_month_to_date?.retention_percent || "Nil",
          previous_month:
            response.data.previous_month?.retention_percent || "Nil",
        },
        {
          title: "Bounce back %",
          current_month:
            response.data.current_month_to_date?.bounce_back_percent || "Nil",
          previous_month:
            response.data.previous_month?.bounce_back_percent || "Nil",
        },
      ];
    },
    setBounceBackTableData(response) {
      if (!this.showBounceBack) {
        return;
      }
      this.bounceback_table_data = [
        {
          title: "",
          current_month: "Current Month",
          previous_month: "Previous Month",
        },
        {
          title: "Bounce Back",
          current_month:
            response.data.current_month_to_date?.bounce_back || "Nil",
          previous_month: response.data.previous_month?.bounce_back || "Nil",
        },
        {
          title: "Bounce back collection",
          current_month: currencyFormat(
            response.data.current_month_to_date?.bounce_back_collection || "Nil"
          ),
          previous_month: currencyFormat(
            response.data.previous_month?.bounce_back_collection || "Nil"
          ),
        },
        {
          title: "Bounce back %",
          current_month:
            response.data.current_month_to_date?.bounce_back_percent || "Nil",
          previous_month:
            response.data.previous_month?.bounce_back_percent || "Nil",
        },
      ];
    },
  },
};
</script>
<style>
.user-stats .card {
  min-height: 11rem;
}

@media (max-width: 992px) {
  .user-stats .card {
    min-height: 9rem;
  }
}
</style>
