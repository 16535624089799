<template>
  <div class="mb-4 card">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex">
        <h6 class="mb-1">Plans</h6>
        <div class="text-end ms-auto">
          <button
            v-if="showNewPlanButton"
            data-bs-toggle="modal"
            data-bs-target="#plan-modal"
            type="button"
            class="mb-0 btn btn-xs bg-gradient-success"
          >
            <i class="fas fa-plus pe-2" aria-hidden="true"></i> New plan
          </button>
        </div>
      </div>
    </div>
    <div class="p-3 card-body">
      <div class="table-responsive">
        <table class="table align-items-center mb-0 hover">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Name
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Start date
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                End date
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Plan days
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Pending delivery days
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Status
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Sequence
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Id
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(plan, index) of plans" :key="index">
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="avatar avatar-md me-3">
                    <img :src="plan.image" alt="table image" class="rounded" />
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">
                      {{ plan.name }}
                    </h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-sm text-secondary mb-0">
                  {{ plan.start_date }}
                </p>
              </td>
              <td>
                <p class="text-sm text-secondary mb-0">
                  {{ plan.actual_end_date }}
                </p>
              </td>
              <td class="text-center">
                <p class="text-sm text-secondary mb-0">
                  {{ plan.total_days }}
                </p>
              </td>
              <td class="text-center">
                <p class="text-sm text-secondary mb-0">
                  {{ plan.remaining_days }}
                </p>
              </td>
              <td class="text-center">
                <p class="text-sm text-secondary mb-0">
                  {{ plan.status }}
                </p>
              </td>
              <td class="text-center">
                <p class="text-sm text-secondary mb-0">
                  {{ plan.sequence }}
                </p>
              </td>
              <td class="text-center">
                <p class="text-sm text-secondary mb-0">
                  {{ plan.id }}
                </p>
              </td>
              <td class="text-center">
                <span
                  class="cursor-pointer"
                  :data-plan-id="plan.id"
                  data-bs-toggle="modal"
                  data-bs-target="#plan-modal"
                >
                  <i
                    v-if="plan.status === 'Draft'"
                    class="far fa-edit text-secondary"
                  ></i>
                  <i v-else class="fa fa-eye text-secondary"></i>
                </span>
                <router-link
                  :to="{
                    name: 'Client Plan',
                    params: {
                      id: clientId,
                      planId: plan.id,
                    },
                  }"
                  class="mx-3"
                  ><i class="fas fa-shipping-fast text-secondary"></i
                ></router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlansCard",
  props: {
    showNewPlanButton: {
      type: Boolean,
      default: true,
    },
    clientId: {
      type: [String, Number],
      required: true,
    },
    plans: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
