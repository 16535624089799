<template>
  <div class="py-4 container-fluid">
    <general-dashboard
      v-if="!loading"
      :get-dashboard-data="API.getAccountAdminDashboardData"
      :show-revenue="true"
    />
  </div>
</template>
<script>
import GeneralDashboard from "./components/GeneralDashboard.vue";
import API from "@/services/api";

export default {
  name: "AccountAdminDashboard",
  components: {
    GeneralDashboard,
  },
  data() {
    return {
      API,
    };
  },
};
</script>
