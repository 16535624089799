<template>
  <info-card :left-details="leftDetails" :right-details="rightDetails" />
</template>

<script>
import InfoCard from "@/components/InfoCard.vue";

export default {
  components: {
    InfoCard,
  },

  props: {
    plan: {
      type: Object,
      required: true,
    },
  },
  computed: {
    leftDetails() {
      return [
        { label: "Plan days", value: this.plan.plan_days },
        { label: "Compensatory days", value: this.plan.compensatory_days },
        { label: "Referral days", value: this.plan.referral_days },
        { label: "Promo days", value: this.plan.promo_days },
      ];
    },
    rightDetails() {
      return [
        { label: "Used days", value: this.plan.used_days },
        { label: "Total days", value: this.plan.total_days },
        { label: "Pending days", value: this.plan.pending_days },
      ];
    },
  },
};
</script>
