<template>
  <modal
    :modal-id="modalId"
    :dialog-class="dialogClass"
    :content-class="contentClass"
    :show-close-button="showCloseButton"
    :static-backdrop="true"
    @close="$emit('close')"
  >
    <template #header>
      <h6>{{ title }}</h6>
    </template>
    <div class="text-center d-flex align-items-center justify-content-center">
      <img
        v-if="imageUrl"
        :src="imageUrl"
        class="img-fluid max-h-75vh"
        alt="Preview Image"
      />
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  name: "ImageModal",
  components: {
    Modal,
  },
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "Image Preview",
    },
    modalId: {
      type: String,
      required: true,
    },
    dialogClass: {
      type: String,
      default: "modal-lg",
    },
    contentClass: {
      type: String,
      default: "",
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["close"],
};
</script>

<style scoped>
.img-size {
  height: 100%;
  object-fit: cover;
}
.img-width {
  max-width: 500px;
}
.max-h-75vh {
  max-height: 75vh;
  overflow: scroll;
}
</style>
