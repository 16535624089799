<template>
  <div class="d-flex mb-3">
    <div class="my-auto">
      <a
        href="javascript:;"
        class="avatar avatar-xl"
        data-bs-toggle="tooltip"
        :title="menuItem.recipe.data.name"
      >
        <img
          :alt="menuItem.recipe.data.name"
          :src="menuItem.recipe.data.image"
          class
          @click="openImageModal(menuItem)"
        />
      </a>
    </div>
    <div :class="$store.state.isRTL ? 'me-3' : 'ms-3'">
      <div class="numbers">
        <h6 class="mb-1 text-dark text-sm">
          <span
            :class="
              menuItem.creator_type == 'client' ? ' bg-primary text-white' : ' '
            "
          >
            {{ menuItem.recipe.data.type }}
            <dislikes-allergens-icons :menu-item="menuItem" />
          </span>
        </h6>
        <div v-if="menuItem.customizations" class="text-sm">
          {{ menuItem.customizations }}
        </div>
        <div>
          <span class="text-sm">
            {{ menuItem.recipe.data.name }}
          </span>
          <span
            data-bs-toggle="modal"
            data-bs-target="#menu-modal"
            :data-menu-item-code="menuItem.meal_code"
            :data-menu-item-id="menuItem.id"
            :data-menu-item="JSON.stringify(menuItem)"
            :data-daily-macros="JSON.stringify(dailyMacros)"
            class="ms-2 cursor-pointer"
            ><i class="far fa-edit text-secondary"></i
          ></span>
          <span
            class="ms-2 cursor-pointer"
            @click="$emit('deleteMenu', menuItem.meal_code)"
            ><i class="fas fa-trash text-secondary"></i
          ></span>
        </div>
        <div>
          <span class="text-sm"
            >Calories: {{ menuItem["recipe.macros"].calories }}</span
          >
          <span class="text-sm ms-1"
            >Carbs: {{ menuItem["recipe.macros"].carbs }}</span
          >
          <span class="text-sm ms-1"
            >Fat: {{ menuItem["recipe.macros"].fat }}</span
          >
          <span class="text-sm ms-1"
            >Protein: {{ menuItem["recipe.macros"].protein }}</span
          >
        </div>
      </div>
    </div>
    <image-display-modal
      v-if="imageModal.show"
      :image-url="imageModal.imageUrl"
      :modal-id="imageModal.id"
      :title="imageModal.title"
      dialog-class="modal-lg"
      @close="closeModalObject(imageModal)"
    />
  </div>
</template>

<script>
import DislikesAllergensIcons from "@/components/DislikesAllergensIcons.vue";
import ImageDisplayModal from "@/views/pages/Clients/components/ImageDisplayModal.vue";
import { showModal, closeModal } from "@/lib/bootstrap";

export default {
  name: "MenuItem",
  components: {
    DislikesAllergensIcons,
    ImageDisplayModal,
  },
  props: {
    menuItem: {
      type: Object,
      required: true,
    },
    dailyMacros: {
      type: Object,
      required: true,
    },
  },
  emits: ["deleteMenu"],
  data() {
    return {
      imageModal: {
        show: false,
        id: "image-preview-modal",
        imageUrl: "",
        title: "",
      },
    };
  },
  methods: {
    openImageModal(file) {
      this.imageModal.imageUrl = file.recipe.data.image;
      this.imageModal.title = file.recipe.data.name;
      this.showModalObject(this.imageModal);
    },
    closeImageModal() {
      this.closeModalObject(this.imageModal);
    },
    showModalObject(modalObject) {
      modalObject.show = true;
      this.$nextTick(() => {
        showModal(modalObject.id);
      });
    },
    closeModalObject(modalObject) {
      this.imageModal.imageUrl = null;
      modalObject.show = false;
      closeModal(modalObject.id);
    },
  },
};
</script>
