<template>
  <div class="py-4 container-fluid">
    <general-dashboard
      :get-dashboard-data="API.getAccountManagersDashboardData"
      :show-deliveries="true"
    />
  </div>
</template>
<script>
import GeneralDashboard from "./components/GeneralDashboard.vue";
import API from "@/services/api";

export default {
  name: "AccountManagerDashboard",
  components: {
    GeneralDashboard,
  },
  data() {
    return {
      API,
    };
  },
};
</script>
