<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div>
        <div class="card">
          <div class="card-body">
            <div class="text-center card-body">
              <h3 class="text-gradient text-success">
                <span class="text-lg ms-n1"></span>
                <span>Welcome!</span>
              </h3>
              <h6 class="mb-0 font-weight-bolder">You are logged in!</h6>
              <p>
                Feel free to explore the available sections or contact us if you
                need any assistance.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DashboardDefault",
  async beforeMount() {
    await this.$store.dispatch(
      "auth/redirectToAvailableDashboard",
      this.$router
    );
  },
};
</script>
