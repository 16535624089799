<template>
  <div class="py-4 container-fluid">
    <div class="row mt-4">
      <div class="col-lg-4">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="font-weight-bolder">Plan Image</h5>
            <div class="row">
              <div class="col-12">
                <img
                  class="mt-3 shadow-lg w-100 border-radius-lg"
                  :src="plan.image"
                  alt="plan image"
                  @click="showImagePicker"
                />

                <input
                  ref="file"
                  type="file"
                  style="display: none"
                  accept="image/png, image/gif, image/jpeg"
                  @change="selectImage"
                />
              </div>
              <div class="mt-5 col-12">
                <div class="d-flex">
                  <button
                    class="mb-0 btn bg-gradient-success btn-sm me-2"
                    type="button"
                    name="button"
                    @click="showImagePicker"
                  >
                    Upload
                  </button>
                  <button
                    v-if="plan.image != planDefaultImage && false"
                    class="mb-0 btn btn-outline-dark btn-sm"
                    type="button"
                    name="button"
                    @click="removeImage"
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 col-lg-8 mt-lg-0">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">Plan Information</h5>
            <div class="row">
              <div class="col-12 col-sm-6">
                <label>Name</label>
                <input
                  id="plan-name"
                  v-model="plan.name.en"
                  class="form-control"
                  type="text"
                  placeholder="Plan name"
                />
              </div>
              <div class="col-12 col-sm-6">
                <label>Name (Arabic)</label>
                <input
                  id="plan-name-ar"
                  v-model="plan.name.ar"
                  class="form-control"
                  type="text"
                  placeholder="Plan name"
                  dir="rtl"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-6">
                <label class="mt-4">Description</label>
                <textarea
                  id="plan-description"
                  v-model="plan.description.en"
                  class="form-control"
                  rows="4"
                >
                </textarea>
              </div>
              <div class="col-12 col-sm-6">
                <label class="mt-4">Description (Arabic)</label>
                <textarea
                  id="plan-description-ar"
                  v-model="plan.description.ar"
                  class="form-control"
                  rows="4"
                  dir="rtl"
                >
                </textarea>
              </div>
              <div class="col-12 col-sm-6">
                <label class="mt-4">Short Description</label>
                <textarea
                  id="plan-short-description"
                  v-model="plan.short_description.en"
                  class="form-control"
                  rows="4"
                >
                </textarea>
              </div>
              <div class="col-12 col-sm-6">
                <label class="mt-4">Short Description (Arabic)</label>
                <textarea
                  id="plan-short-description-ar"
                  v-model="plan.short_description.ar"
                  class="form-control"
                  rows="4"
                  dir="rtl"
                >
                </textarea>
              </div>
            </div>
            <div class="row">
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label class="mt-4">Alias</label>
                <input v-model="plan.alias" class="form-control" type="text" />
              </div>
              <div class="col-12 col-sm-6">
                <label class="mt-4">Type</label>
                <select
                  id="plan-type"
                  v-model="plan.type"
                  class="form-control"
                ></select>
              </div>
              <div class="col-12 col-sm-6">
                <label class="mt-4">Size</label>
                <select
                  id="plan-size"
                  v-model="plan.size"
                  class="form-control"
                ></select>
              </div>
              <div class="col-12 col-sm-6">
                <label class="mt-4">Sort order</label>
                <input
                  id="plan-sort-order"
                  v-model="plan.sort_order"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-12 col-sm-6">
                <label class="mt-4">Group</label>
                <input
                  id="plan-group"
                  v-model="plan.group"
                  class="form-control"
                  type="text"
                />
              </div>

              <div class="col-12 col-sm-auto">
                <label class="mt-4">Status</label>
                <div class="d-flex">
                  <label class="form-check-label mb-0 me-2"> Active </label>
                  <argon-switch
                    id="plan-status-switch"
                    v-model:checked="plan.status"
                    name="plan_status"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-auto">
                <label class="mt-4">Is public</label>
                <div class="d-flex">
                  <label class="form-check-label mb-0 me-2"> Yes </label>
                  <argon-switch
                    id="plan-is-public-switch"
                    v-model:checked="plan.is_public"
                    name="plan_is_public"
                  />
                </div>
              </div>
            </div>

            <div
              class="text-right col-lg-12 d-flex flex-column justify-content-center"
            >
              <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
                <button
                  v-if="$can('update', 'plans')"
                  type="button"
                  class="btn btn-success"
                  @click="updatePlan"
                >
                  Save plan
                </button>
                <router-link
                  :to="{ name: 'Plans' }"
                  class="ms-3 btn btn-white text-success"
                >
                  Back
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <plan-variants-list-table :plan-id="$route.params.id" />
      </div>
      <div
        class="text-right col-lg-12 d-flex flex-column justify-content-center mt-4"
      >
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <button
            v-if="$can('update', 'plans')"
            type="button"
            class="btn btn-white text-success"
            @click="updatePlan"
          >
            Save plan
          </button>
          <router-link
            :to="{ name: 'Plans' }"
            class="ms-3 btn btn-white text-success"
          >
            Back
          </router-link>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12 col-md-6">
        <meta-editor
          v-if="plan.meta"
          :initial-meta="plan.meta"
          @update:meta="updateMeta"
        />
      </div>
      <div class="col-12 col-md-6">
        <plan-images-card
          :existing-plan-images="plan.planImages"
          :clear-plan-images="clearPlanImages"
          @update:newPlanImages="updateNewPlanImages"
        />
      </div>
      <div
        class="text-right col-lg-12 d-flex flex-column justify-content-center mt-4"
      >
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <button
            v-if="$can('update', 'plans')"
            type="button"
            class="btn btn-white text-success"
            @click="updatePlan"
          >
            Save plan
          </button>
          <router-link
            :to="{ name: 'Plans' }"
            class="ms-3 btn btn-white text-success"
          >
            Back
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { initChoices } from "@/assets/js/init-choices";
import planDefaultImage from "@/assets/img/plan.png";
import API from "@/services/api";
import { handleError } from "@/lib/helpers";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import { showMessage } from "@/assets/js/show-message";
import PlanVariantsListTable from "@/views/pages/Config/PlanVariants/components/ListTable";
import MetaEditor from "@/views/pages/Config/Plans/components/MetaEditor";
import PlanImagesCard from "@/views/pages/Config/Plans/components/PlanImagesCard";
export default {
  name: "Edit",
  components: {
    ArgonSwitch,
    PlanVariantsListTable,
    MetaEditor,
    PlanImagesCard,
  },
  data() {
    return {
      planDefaultImage,
      planImage: undefined,
      planImages: [],
      plan: {
        name: { en: "", ar: "" },
        description: { en: "", ar: "" },
        short_description: { en: "", ar: "" },
      },
      planVariants: [],
      loading: {},
      formSubmitted: false,
      clearPlanImages: false,
    };
  },
  async mounted() {
    await this.setPlan();
    await this.initPlanTypeChoices();
    this.initPlanSizeChoices();
  },
  methods: {
    async initPlanTypeChoices() {
      await API.getPlanTypes()
        .then((res) => {
          initChoices(
            "plan-type",
            {
              choices: API.formatDataToChoicesJs(res.data.data, "", {
                value: "value",
                label: "name",
                id: "value",
              }),
            },
            this.plan.type
          );
        })
        .catch((err) => {
          const response = handleError(err);
          showMessage(response.message, "error");
        });
    },
    initPlanSizeChoices() {
      initChoices("plan-size", { choices: API.getPlanSizes() }, this.plan.size);
    },
    async setPlan() {
      try {
        const response = await API.getPlan(this.$route.params.id);
        const planData = response.data.data;
        this.plan = {
          name: {
            en: planData.translations.name.en,
            ar: planData.translations.name.ar,
          },
          description: {
            en: planData.translations.description.en,
            ar: planData.translations.description.ar,
          },
          short_description: {
            en: planData.translations.short_description.en,
            ar: planData.translations.short_description.ar,
          },
          sort_order: planData.sort_order,
          alias: planData.alias,
          group: planData.group,
          type: planData.type,
          is_public: planData.is_public,
          status: planData.status === "Active",
          image: planData.image.length ? planData.image : this.planDefaultImage,
          size: planData.size,
          meta: planData.meta,
          planImages: planData.planImages.data || [],
        };

        this.planVariants = planData.planVariants.data;
      } catch (err) {
        if (err.response && err.response.data.status === 404) {
          this.$swal({
            icon: "error",
            title: "Plan not found",
            willClose: () => {
              this.$router.push({ name: "Plans" });
            },
          });
        } else {
          const response = handleError(err);
          showMessage(response.message, "error");
        }
      }
    },

    async updatePlan() {
      if (this.formSubmitted) return;

      this.formSubmitted = true;
      const formData = this.prepareFormData();

      try {
        await API.updatePlan(this.$route.params.id, formData);
        this.setPlan();
        this.planImages = [];
        this.clearPlanImages = true;
        showMessage("Plan saved successfully", "success");
      } catch (error) {
        const response = handleError(error);
        showMessage(response.message, "error");
      } finally {
        this.formSubmitted = false;
      }
    },
    appendFormData(formData, key, data) {
      if (data && typeof data === "object" && !(data instanceof File)) {
        Object.keys(data).forEach((nestedKey) => {
          this.appendFormData(
            formData,
            `${key}[${nestedKey}]`,
            data[nestedKey] !== undefined ? data[nestedKey] : ""
          );
        });
      } else {
        formData.append(key, data !== undefined ? data : "");
      }
    },
    prepareFormData() {
      const formData = new FormData();

      this.appendFormData(formData, "name", this.plan.name);
      this.appendFormData(formData, "description", this.plan.description);
      this.appendFormData(
        formData,
        "short_description",
        this.plan.short_description
      );

      // Append all other fields except for excluded keys
      Object.entries(this.plan).forEach(([key, value]) => {
        if (
          ![
            "name",
            "description",
            "short_description",
            "image",
            "meta",
          ].includes(key)
        ) {
          formData.append(key, value || "");
        }
      });

      formData.set("is_public", this.plan.is_public ? "1" : "0");
      formData.set("status", this.plan.status ? "Active" : "Inactive");
      formData.set("_method", "PATCH");

      if (this.planImage) {
        formData.set("image", this.planImage);
      }
      this.planImages.forEach((item, index) => {
        formData.append(`plan_images[${index}]`, item.file);
      });

      // Handle meta data
      if (this.plan.meta) {
        this.appendFormData(formData, "meta", this.plan.meta);
      }

      return formData;
    },
    showImagePicker() {
      this.$refs.file.click();
    },
    selectImage() {
      this.planImage = this.$refs.file.files.item(0);
      this.plan.image = URL.createObjectURL(this.planImage);
    },
    removeImage() {
      this.planImage = undefined;
      this.plan.image = this.planDefaultImage;
    },
    updateMeta(newMeta) {
      this.plan.meta = newMeta;
    },
    updateNewPlanImages(newImages) {
      this.planImages = newImages;
    },
  },
};
</script>
