<template>
  <div class="card">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex justify-content-between">
        <h6 class="mb-2">{{ title }}</h6>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table align-items-center">
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td class="w-25 px-3">
              <div class="">
                <p class="mb-0 text-xs font-weight-bold">
                  {{ item?.title }}
                </p>
              </div>
            </td>
            <td>
              <div class="">
                <div>
                  <h6 class="mb-0 text-sm">
                    {{ item?.current_month }}
                  </h6>
                </div>
              </div>
            </td>
            <td>
              <div class="">
                <div>
                  <h6 class="mb-0 text-sm">
                    {{ item?.previous_month }}
                  </h6>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  name: "BounceBackCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
