<template>
  <div class="py-4 container-fluid">
    <general-dashboard
      :get-dashboard-data="API.getSupervisorDashboardData"
      :show-revenue="true"
    />
  </div>
</template>
<script>
import GeneralDashboard from "./components/GeneralDashboard.vue";
import API from "@/services/api";

export default {
  name: "AccountSupervisorDashboard",
  components: {
    GeneralDashboard,
  },
  data() {
    return {
      API,
    };
  },
};
</script>
