import ApiRecipes from "@/services/apiRecipes";
import ApiAllergens from "@/services/apiAllergens";
import apiFoodGroups from "@/services/apiFoodGroups";
import apiIngredients from "@/services/apiIngredients";
class ApiClientDislikes {
  async get(dislikable_type, search_value) {
    if (dislikable_type === "allergen") {
      return await ApiAllergens.get("start=0&length=-1");
    } else if (dislikable_type === "food_group") {
      return await apiFoodGroups.get("start=0&length=-1");
    } else if (
      ["ingredient", "ingredient_allergen"].includes(dislikable_type)
    ) {
      return await apiIngredients.get("start=0&length=-1");
    } else if (dislikable_type === "recipe") {
      let params = "start=0&length=50";
      if (search_value) {
        params =
          "draw=10&columns[0][data]=id&columns[0][name]=id&columns[0][searchable]=true&columns[0][orderable]=true" +
          "&columns[0][search][value]=&columns[0][search][regex]=false" +
          "&columns[1][data]=name&columns[1][name]=name&columns[1][searchable]=true&columns[1][orderable]=true&columns[1][search][value]=" +
          "&columns[1][search][regex]=false&start=0&length=150&search[value]=" +
          search_value;
      }

      return await ApiRecipes.dropdown(params);
    }
    return { data: [] };
  }
}

export default new ApiClientDislikes();
