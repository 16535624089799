<template>
  <div id="basic-info" class="card mt-4">
    <div class="card-header">
      <h5>{{ role.name }}</h5>
    </div>
    <div class="card-body pt-0">
      <div class="table-responsive">
        <table
          id="role-permissions-table"
          class="table align-items-center mb-0 hover"
          width="100%"
        >
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                ID
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Permission
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <argon-button
        class="float-end mt-5 mb-0"
        color="dark"
        variant="gradient"
        size="sm"
        @click="updateRolePermissions"
        >Update role
      </argon-button>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import ArgonButton from "@/components/ArgonButton.vue";
import API from "@/services/api";
import { showMessage } from "@/assets/js/show-message";

export default {
  name: "EditRoleSideNavItem",
  components: {
    ArgonButton,
  },
  setup() {
    //return {v$: useVuelidate()}
  },
  data() {
    return {
      role: {
        name: undefined,
        permissions: [],
      },
      rolePermissions: [],
      loading: true,
      formSubmitted: false,
    };
  },
  async mounted() {
    await this.setRole();
    await this.initDataTable();
    this.loading = false;
  },
  beforeUnmount() {
    $("#role-permissions-table").DataTable().destroy();
  },
  methods: {
    async updateRolePermissions() {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      let formData = new FormData();
      for (let key in this.rolePermissions) {
        formData.append("permissions[" + key + "]", this.rolePermissions[key]);
      }
      formData.append("_method", "PATCH");

      await API.updateUserRole(this.$route.params.id, formData)
        .then(() => {
          this.$swal({
            html: '<i class="fas fa-check-circle text-md ms-3 text-success"></i> <span>Success. User Role updated.</span>',
            timer: 1500,
            position: "bottom-end",
            showConfirmButton: false,
            timerProgressBar: true,
            width: "22em",
            padding: "0.5em 0.8em",
            customClass: {
              container: "swal2-small",
            },
            backdrop: false,
          });
        })
        .catch((err) => {
          let message = err.message;
          if (err.response && err.response.data && err.response.data.message) {
            message = err.response.data.message;
          }
          this.$swal({
            html:
              '<i class="fas fa-exclamation-triangle text-md ms-3 text-danger"></i> <span>' +
              message +
              "</span>",
            timer: 3500,
            position: "bottom-end",
            showConfirmButton: false,
            timerProgressBar: true,
            width: "22em",
            padding: "0.5em 0.8em",
            customClass: {
              container: "swal2-small",
            },
            backdrop: false,
          });
        });
      setTimeout(() => (this.formSubmitted = false), 1000);
    },
    permissionIsChecked(id) {
      return this.rolePermissions.includes(parseInt(id));
    },
    async setRole() {
      const appInstance = this;
      await API.getRole(appInstance.$route.params.id)
        .then((res) => {
          this.role = res.data.data;
          for (let i in this.role.permissions.data) {
            this.rolePermissions.push(
              parseInt(this.role.permissions.data[i].id)
            );
          }
        })
        .catch((err) => {
          if (err.response.data.status === 404) {
            this.$swal({
              icon: "error",
              title: "User Role not found",
              willClose: () => {
                appInstance.$router.push({ name: "User Roles" });
              },
            });
          } else {
            const response = API.handleError(err);
            showMessage(response.message, "error");
          }
        });
    },

    async initDataTable() {
      const appInstance = this;
      $.fn.dataTable.ext.classes.sWrapper =
        "dataTables_wrapper dataTable-wrapper";
      $.fn.DataTable.ext.pager.numbers_length = 5;
      $("#role-permissions-table").DataTable({
        serverSide: true,
        pagingType: "simple_numbers",
        language: {
          paginate: {
            previous: "‹",
            next: "›",
          },
        },
        columns: [
          {
            data: "id",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                data +
                "</h6></div>"
              );
            },
            width: "3rem",
          },
          {
            data: "name",
            render: function (data, type, row) {
              return (
                '<div class="form-check">\n' +
                "    <input\n" +
                '      id="permission-' +
                row.id +
                '"\n' +
                '      class="form-check-input permission-input"\n' +
                '      type="checkbox"\n' +
                '     "\n' +
                '      value="' +
                row.id +
                '"' +
                "" +
                (appInstance.permissionIsChecked(row.id)
                  ? 'checked="checked"'
                  : "") +
                "" +
                "    />\n" +
                '    <label for="permission-' +
                row.id +
                '" class="custom-control-label text-secondary mb-0 text-sm" >\n' +
                "      " +
                data +
                "\n" +
                "    </label>\n" +
                "  </div>"
              );
            },
          },
        ],
        ajax: function (data, callback) {
          API.getPermissions($.param(data))
            .then((res) => {
              callback(res.data);
            })
            .catch((err) => {
              const response = API.handleError(err);
              showMessage(response.message, "error");
            });
        },
      });
      $(document).on("click", ".permission-input", function () {
        const value = parseInt(this.value);
        const index = appInstance.rolePermissions.indexOf(value);
        if ($(this).is(":checked")) {
          if (index < 0) {
            appInstance.rolePermissions.push(value);
          }
        } else {
          if (index >= 0) {
            appInstance.rolePermissions.splice(index, 1);
          }
        }
      });
    },
  },
};
</script>
