<template>
  <div class="card-header">
    <div class="row align-items-center">
      <div class="col-6 col-sm-auto">
        <label class="form-label">Date</label>
        <div class="form-group">
          <flat-pickr
            v-model="filter.date"
            class="form-control"
            placeholder="Please select start date"
          ></flat-pickr>
        </div>
      </div>
      <div v-if="showTypeFilter" class="col-6 col-sm-auto">
        <label class="form-label">Type</label>
        <div class="form-group">
          <select v-model="filter.type" class="form-control">
            <option v-for="(el, key) in types" :key="key" :value="el">
              {{ el }}
            </option>
          </select>
        </div>
      </div>
      <div v-if="showRecipeFilter" class="col-6 col-sm-4">
        <label class="form-label">Recipe</label>
        <div class="form-group">
          <select
            id="filter-recipe-id"
            v-model="filter.recipe_id"
            class="form-control"
          ></select>
        </div>
      </div>
      <div v-if="showMealCodeFilter" class="col-6 col-sm-4">
        <label class="form-label">Meal Type</label>
        <div class="form-group">
          <select
            id="filter-meal-code"
            v-model="filter.meal_code"
            class="form-control"
          ></select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3">
        <button
          v-if="showEmailButton"
          class="btn text-white btn-sm mb-0"
          :class="formSubmitted ? 'btn-dark opacity-5' : 'btn-success'"
          @click="emitEmailEvent"
        >
          {{ formSubmitted ? "Please wait" : "Email" }}
        </button>
        <button
          v-if="showDownloadButton"
          class="btn text-white btn-sm mb-0"
          :class="formSubmitted ? 'btn-dark opacity-5' : 'btn-success'"
          @click="emitDownloadEvent"
        >
          {{ formSubmitted ? "Please wait" : "Download" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ApiRecipes from "@/services/apiRecipes";
import { handleError, showMessage } from "@/lib/helpers";
import { initChoices, formatDataToChoicesJs } from "@/assets/js/init-choices";
import apiMiscList from "@/services/apiMiscList";
export default {
  name: "TdoFilter",
  components: {
    flatPickr,
  },
  props: {
    initialDate: {
      type: String,
      default: null,
    },
    initialType: {
      type: String,
      default: "TDO all",
    },
    types: {
      type: Array,
      default: () => ["TDO all", "TDO am", "TDO pm"],
    },
    showTypeFilter: {
      type: Boolean,
      default: true,
    },
    formSubmitted: {
      type: Boolean,
      default: false,
    },
    showDownloadButton: {
      type: Boolean,
      default: true,
    },
    showEmailButton: {
      type: Boolean,
      default: false,
    },
    showRecipeFilter: {
      type: Boolean,
      default: false,
    },
    showMealCodeFilter: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["download", "email"],
  data() {
    return {
      filter: {
        date: "",
        type: "",
        recipe_id: "",
        meal_code: "",
      },
      recipes: [],
      mealCodeChoices: [],
      loading: {
        recipes: false,
      },
    };
  },
  async mounted() {
    this.filter = {
      date: this.initialDate,
      type: this.initialType,
    };
    if (this.showRecipeFilter) {
      await this.setRecipes();
      this.initRecipeChoices();
    }
    if (this.showMealCodeFilter) {
      this.setMealTypeChoices();
      await this.initMealCodeChoices();
    }
  },
  methods: {
    emitDownloadEvent() {
      this.$emit("download", this.filter);
    },
    emitEmailEvent() {
      this.$emit("email", this.filter);
    },
    setMealTypeChoices() {
      this.mealCodeChoices = formatDataToChoicesJs(
        apiMiscList.mealCodes(),
        "",
        { id: "name", value: "name", label: "value" }
      );
    },
    async initMealCodeChoices() {
      await initChoices("filter-meal-code", {
        choices: this.mealCodeChoices,
        placeholder: false,
      });
    },
    async setRecipes() {
      this.loading.recipes = true;
      this.recipes = [];
      let response = await ApiRecipes.dropdown("start=0&length=50").catch(
        handleError
      );
      if (response.status === 200) {
        this.recipes = ApiRecipes.format(response.data.data);
      } else {
        showMessage(response.message, "error", 5000);
      }
      this.loading.recipes = false;
    },
    async getRecipes(search_value) {
      let params =
        "?draw=10&columns[0][data]=id&columns[0][name]=id&columns[0][searchable]=true&columns[0][orderable]=true" +
        "&columns[0][search][value]=&columns[0][search][regex]=false" +
        "&columns[1][data]=name&columns[1][name]=name&columns[1][searchable]=true&columns[1][orderable]=true&columns[1][search][value]=" +
        "&columns[1][search][regex]=false&start=0&length=150&search[value]=" +
        search_value;
      return await ApiRecipes.dropdown(params).catch(handleError);
    },
    async initRecipeChoices() {
      const id = "filter-recipe-id";
      let element = document.getElementById(id);
      await initChoices(id, {
        choices: formatDataToChoicesJs(this.recipes, []),
        placeholder: false,
      });

      element.addEventListener(
        "search",
        (event) => this.handleSearchRecipe(event, id),
        false
      );
    },
    async handleSearchRecipe(event, id) {
      clearTimeout(this.searchTimeOut);

      this.searchTimeOut = setTimeout(async () => {
        const response = await this.getRecipes(event.detail.value).catch(
          handleError
        );
        if (response.status === 200) {
          this.recipes = ApiRecipes.format(response.data.data);
          await initChoices(id, {
            choices: formatDataToChoicesJs(this.recipes),
          });
        } else {
          showMessage(response.message, "error");
        }
      }, 1000);
    },
  },
};
</script>
