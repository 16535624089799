<template>
  <div class="card">
    <refund-requests-list-table-filter
      class="card-header"
      :initial-filter="filter"
      @download="downloadExcel"
      @filter="drawDataTable"
    />
    <div class="card-body">
      <div class="table-responsive">
        <table
          id="list-table"
          class="table align-items-center mb-0 hover"
          width="100%"
        >
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Invoice ID
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Created
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Name
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Account manager
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Refund date
              </th>

              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Refund type
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Cancellation<br />
                charge percentage
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Refund medium
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Payment source
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Bank
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Bank <br />account name
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Bank <br />account number
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Master area
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Street, Building, <br />
                Room no. or Villa no.
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Refund amount
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Action
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import ApiInvoiceRefundRequests from "@/services/apiInvoiceRefundRequests";
import apiAddresses from "@/services/apiAddresses";
import { showMessage } from "@/assets/js/show-message";
import { currencyFormat, handleError, downloadFile } from "@/lib/helpers";
import { dateFormat } from "@/lib/dateHelper";
import RefundRequestsListTableFilter from "./RefundRequestsListTableFilter.vue";

export default {
  name: "ListTable",
  components: {
    RefundRequestsListTableFilter,
  },
  data() {
    return {
      areas: [],
      dataTable: undefined,
      formSubmitted: false,
      filter: {
        start_date: dateFormat("current", "YYYY-MM-DD"),
        end_date: dateFormat("current", "YYYY-MM-DD"),
        status: "Pending",
        online: true,
      },
      listTable: undefined,
    };
  },
  watch: {
    formSubmitted(newVal) {
      if (newVal) {
        this.listTable.find(".action").addClass("d-none");
        this.listTable.find(".spinner-border").removeClass("d-none");
      } else {
        this.listTable.find(".action").removeClass("d-none");
        this.listTable.find(".spinner-border").addClass("d-none");
      }
    },
  },
  async mounted() {
    this.listTable = $("#list-table");
    await this.setAreas();
    if (this.dataTable) {
      this.dataTable.destroy();
    }
    const appInstance = this;
    $.fn.dataTable.ext.classes.sWrapper =
      "dataTables_wrapper dataTable-wrapper";
    $.fn.DataTable.ext.pager.numbers_length = 5;
    this.dataTable = this.listTable.DataTable({
      processing: true,
      serverSide: true,
      searching: false,
      pagingType: "simple_numbers",
      language: {
        paginate: {
          previous: "‹",
          next: "›",
        },
      },
      columns: [
        {
          data: "invoice_id",
          render: function (data) {
            return (
              '<div class="d-flex flex-column justify-content-center"><span class="text-dark text-xs">#' +
              data +
              "</span></div>"
            );
          },
        },
        {
          data: "created_at",
          render: function (data) {
            return (
              '<div class="d-flex flex-column justify-content-center"><span class="text-dark text-xs">' +
              dateFormat(data, "DD MMM YYYY") +
              "</span></div>"
            );
          },
        },
        {
          data: "name",
          render: function (data) {
            return (
              '<div class="d-flex flex-column justify-content-center"><span class="text-dark text-xs">' +
              data +
              "</span></div>"
            );
          },
          searchable: false,
          orderable: false,
        },
        {
          data: "account_manager_name",
          render: function (data) {
            return (
              '<div class="d-flex flex-column justify-content-center"><span class="text-dark text-xs">' +
              data +
              "</span></div>"
            );
          },
        },
        {
          data: "meta",
          render: function (data) {
            return (
              '<div class="d-flex flex-column justify-content-center"><span class="text-dark text-xs">' +
              dateFormat(data.refund.date) +
              "</span></div>"
            );
          },
          searchable: false,
          orderable: false,
        },

        {
          data: "type",
          render: function (data) {
            return (
              '<div class="d-flex flex-column justify-content-center"><span class="text-dark text-xs">' +
              data +
              "</span></div>"
            );
          },
        },
        {
          data: "cancellation_charge_percent",
          render: function (data) {
            return (
              '<div class="d-flex flex-column justify-content-center"><span class="text-dark text-xs">' +
              data +
              "</span></div>"
            );
          },
        },
        {
          data: "meta",
          render: function (data) {
            return (
              '<div class="d-flex flex-column justify-content-center"><span class="text-dark text-xs">' +
              data.refund.medium +
              "</span></div>"
            );
          },
        },
        {
          data: "payment_source",
          render: function (data) {
            return (
              '<div class="d-flex flex-column justify-content-center"><span class="text-dark text-xs">' +
              data +
              "</span></div>"
            );
          },
        },
        {
          data: "meta",
          render: function (data) {
            return (
              '<div class="d-flex flex-column justify-content-center"><span class="text-dark text-xs">' +
              data.bank.name +
              "</span></div>"
            );
          },
        },
        {
          data: "meta",
          render: function (data) {
            return (
              '<div class="d-flex flex-column justify-content-center"><span class="text-dark text-xs">' +
              data.bank.account.name +
              "</span></div>"
            );
          },
        },
        {
          data: "meta",
          render: function (data) {
            return (
              '<div class="d-flex flex-column justify-content-center"><span class="text-dark text-xs">' +
              data.bank.account.number +
              "</span></div>"
            );
          },
        },
        {
          data: "meta",
          render: function (data) {
            return (
              '<div class="d-flex flex-column justify-content-center"><span class="text-dark text-xs">' +
              appInstance.getAreaName(data.address.area_id) +
              "</span></div>"
            );
          },
        },
        {
          data: "meta",
          render: function (data) {
            return (
              '<div class="d-flex flex-column justify-content-center"><span class="text-dark text-xs">' +
              data.address.line1 +
              "</span></div>"
            );
          },
        },
        {
          data: "refund_amount",
          render: function (data) {
            return (
              '<div class="d-flex flex-column"><span class="text-dark text-xs">' +
              currencyFormat(data) +
              "</span></div>"
            );
          },
          searchable: false,
          orderable: false,
          className: "align-middle  text-sm",
        },
        {
          data: "status",
          render: function (data) {
            return (
              '<span class="badge badge-dot me-4"><i class="' +
              (data == "Approved" ? "bg-success" : "bg-warning") +
              '"></i><span class="text-dark text-xs">' +
              data +
              "</span></span>"
            );
          },
          searchable: false,
          orderable: false,
          className: "align-middle text-sm",
        },
        {
          data: null,
          render: function (data) {
            let button =
              '<div class="spinner-border spinner-border-sm d-none" role="status">  <span class="visually-hidden">Loading...</span></div>';
            if (
              data.status === "Pending" &&
              appInstance.$ability.can("approve", "invoice_refund_requests")
            ) {
              button +=
                '<span class="action cursor-pointer action-' +
                data.invoice_id +
                "-" +
                data.id +
                '"><i class="fa fa-check fa-lg text-success approve-refund-request" ' +
                'data-id="' +
                data.id +
                '" data-invoice_id="' +
                data.invoice_id +
                '"></i></span> ';
            }
            if (
              data.status === "Pending" &&
              appInstance.$ability.can("reject", "invoice_refund_requests")
            ) {
              button +=
                '<span class="action cursor-pointer action-' +
                data.invoice_id +
                "-" +
                data.id +
                '"><i class="fas fa-times fa-lg ms-3 text-danger reject-refund-request" ' +
                'data-id="' +
                data.id +
                '" data-invoice_id="' +
                data.invoice_id +
                '"></i></span> ';
            }
            if (appInstance.$ability.can("update", "invoice_refund_requests")) {
              button +=
                '<a href="' +
                appInstance.$router.resolve({
                  name: "Edit Invoice Refund Request",
                  params: {
                    invoice_id: data.invoice_id,
                    id: data.id,
                  },
                }).href +
                '" class="action mx-3"><i class="far fa-edit fa-lg text-secondary"></i></a>';
            }
            return button;
          },
          className: "align-middle text-center text-sm",
          orderable: false,
        },
      ],
      ajax: async function (data, callback) {
        data.filter = appInstance.filter;
        const response = await ApiInvoiceRefundRequests.list(data).catch(
          handleError
        );
        if (response.status == 200) {
          callback(response.data);
        } else {
          showMessage(response.message, "error");
        }
      },
    });
    $(document)
      .off("click", ".approve-refund-request")
      .on("click", ".approve-refund-request", function () {
        appInstance.approveRefundRequest(
          $(this).data("invoice_id"),
          $(this).data("id")
        );
      });
    $(document)
      .off("click", ".reject-refund-request")
      .on("click", ".reject-refund-request", function () {
        appInstance.rejectRefundRequest(
          $(this).data("invoice_id"),
          $(this).data("id")
        );
      });
  },
  beforeUnmount() {
    this.dataTable.destroy();
  },
  methods: {
    dateFormat,
    getAreaName(areaId) {
      const area = this.areas.find((el) => el.id == areaId);
      if (area) {
        return area.name;
      }
      return [];
    },
    async setAreas() {
      this.areas = await apiAddresses.getAreas("start=0&length=-1");
    },
    async approveRefundRequest(invoice_id, invoice_refund_request_id) {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const response = await ApiInvoiceRefundRequests.approve(
        invoice_id,
        invoice_refund_request_id
      ).catch(handleError);
      if (response.status == 200) {
        this.dataTable
          .row(
            $(
              ".action-" + invoice_id + "-" + invoice_refund_request_id
            ).parents("tr")
          )
          .remove()
          .draw();
        showMessage("Invoice refund request approved successfully", "success");
      } else {
        showMessage(response.message, "error");
      }
      this.formSubmitted = false;
    },
    async rejectRefundRequest(invoice_id, invoice_refund_request_id) {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const response = await ApiInvoiceRefundRequests.reject(
        invoice_id,
        invoice_refund_request_id
      ).catch(handleError);
      if (response.status == 200) {
        this.dataTable
          .row(
            $(
              ".action-" + invoice_id + "-" + invoice_refund_request_id
            ).parents("tr")
          )
          .remove()
          .draw();
        showMessage("Invoice refund request rejected successfully", "success");
      } else {
        showMessage(response.message, "error");
      }
      this.formSubmitted = false;
    },
    drawDataTable(data) {
      this.filter = { ...data };
      this.dataTable.draw();
    },
    async downloadExcel(data) {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const options = {
        ...{ filter: data, action: "download" },
      };
      let response = await ApiInvoiceRefundRequests.list(options).catch(
        handleError
      );
      if (response.status === 200) {
        downloadFile(
          response.data,
          "Invoice refund requests " +
            data.start_date +
            " " +
            data.end_date +
            ".xlsx",
          response.headers["content-disposition"]
        );
      } else {
        showMessage(response.message, "error");
      }
      this.formSubmitted = false;
    },
  },
};
</script>
