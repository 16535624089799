<template>
  <div class="py-4 container-fluid">
    <general-dashboard
      :get-dashboard-data="API.getSalesAgentDashboardData"
      :show-client-plans-expiring="false"
      :show-retention-clients="false"
      :show-retention-bounce-back="false"
      :show-bounce-back="true"
      layout="sales"
    />
  </div>
</template>
<script>
import GeneralDashboard from "./components/GeneralDashboard.vue";
import API from "@/services/api";

export default {
  name: "SalesAgentDashboard",
  components: {
    GeneralDashboard,
  },
  data() {
    return {
      API,
    };
  },
};
</script>
