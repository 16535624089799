<template>
  <div class="card">
    <div class="card-body">
      <h5 class="font-weight-bolder">{{ cardLabel }}</h5>
      <input
        ref="files"
        type="file"
        multiple
        accept="image/png, image/jpeg, application/pdf"
        style="display: none"
        @change="selectFiles"
      />
      <button
        v-if="!viewOnly"
        class="btn btn-sm btn-success"
        @click="showPlanFilePicker"
      >
        Select Files
      </button>
      <div class="mt-3 row">
        <div class="row mt-3">
          <div
            v-for="(file, index) in existingPlanImages"
            :key="'existing-' + index"
            class="col-md-3 mb-3"
            :class="{ 'min-h-150': deleteOption }"
          >
            <div
              v-if="file.mime_type === 'application/pdf'"
              class="img-thumbnail img-thumb-height"
            >
              <a
                :href="file.url"
                target="_blank"
                class="h-100 w-100 d-flex align-items-center justify-content-center"
                >{{ file.name }}.pdf</a
              >
            </div>
            <img
              v-else
              :src="file.url"
              class="img-fluid img-thumbnail img-thumb-height"
              alt="Plan image"
              @click="openImageModal(file)"
            />
            <button
              v-if="deleteOption"
              class="btn btn-danger btn-sm mt-2 w-100"
              @click="removeExistingFile(file)"
            >
              Remove
            </button>
          </div>
        </div>
        <div
          v-for="(file, index) in newFileUpdate"
          :key="'new-' + index"
          class="mt-2 col-md-3 col-12 min-h-150"
        >
          <div
            v-if="file?.file.type === 'application/pdf'"
            class="h-100 img-fluid img-thumbnail img-thumb-height"
          >
            <a
              :href="file.url"
              target="_blank"
              class="h-100 w-100 d-flex align-items-center justify-content-center text-break text-center"
              >{{ file.file.name }}</a
            >
          </div>
          <img
            v-else
            :src="file.url"
            class="img-fluid img-thumbnail img-thumb-height img-thumb-height"
            alt="plan image"
            @click="openImageModal(file)"
          />
          <button
            class="btn btn-danger btn-sm mt-2 w-100"
            @click="removeFiles(index)"
          >
            Remove
          </button>
        </div>
      </div>
    </div>
    <image-display-modal
      v-if="imageModal.show"
      :image-url="imageModal.imageUrl"
      :modal-id="imageModal.id"
      :title="imageModal.title"
      @close="closeModalObject(imageModal)"
    />
  </div>
</template>

<script>
import ImageDisplayModal from "@/views/pages/Clients/components/ImageDisplayModal.vue";
import { showModal, closeModal } from "@/lib/bootstrap";
export default {
  name: "ConsultationImagesCard",
  components: {
    ImageDisplayModal,
  },
  props: {
    existingPlanImages: {
      type: Array,
      default: () => [],
    },
    clearFiles: {
      type: Boolean,
      default: false,
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
    cardLabel: {
      type: String,
      default: "Upload Files",
    },
    allowMultiple: {
      type: Boolean,
      default: true,
    },
    deleteOption: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:newFileUpdate", "delete:file"],
  data() {
    return {
      newFileUpdate: [],
      imageModal: {
        show: false,
        id: "image-preview-modal",
        imageUrl: "",
        title: "",
      },
    };
  },
  watch: {
    clearFiles(newVal) {
      if (newVal) {
        this.newFileUpdate = [];
        this.$emit("update:newFileUpdate", this.newFileUpdate);
      }
    },
  },
  methods: {
    openImageModal(file) {
      this.imageModal.imageUrl = file.url;
      this.imageModal.title = file.name;
      this.showModalObject(this.imageModal);
    },
    closeImageModal() {
      this.closeModalObject(this.imageModal);
    },
    showModalObject(modalObject) {
      modalObject.show = true;
      this.$nextTick(() => {
        showModal(modalObject.id);
      });
    },
    closeModalObject(modalObject) {
      this.imageModal.imageUrl = null;
      modalObject.show = false;
      closeModal(modalObject.id);
    },
    showPlanFilePicker() {
      this.$refs.files.click();
    },
    selectFiles(event) {
      const files = event.target.files;
      if (this.allowMultiple) {
        this.newFileUpdate = Array.from(files).map((file) => ({
          url: URL.createObjectURL(file),
          file: file,
        }));
      } else {
        const file = files[0];
        if (file) {
          this.newFileUpdate = [
            {
              url: URL.createObjectURL(file),
              file: file,
            },
          ];
        }
      }

      this.$emit("update:newFileUpdate", this.newFileUpdate);
    },
    removeFiles(index) {
      this.newFileUpdate.splice(index, 1);
      this.$emit("update:newFileUpdate", this.newFileUpdate);
    },
    removeExistingFile(file) {
      this.$emit("delete:file", file);
    },
  },
};
</script>
<style>
.img-thumb-height {
  height: 100% !important;
  max-height: 100px;
  width: 100%;
  object-fit: cover;
}
.min-h-150 {
  min-height: 150px;
}
</style>
