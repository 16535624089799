<template>
  <div class="card">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-6 mt-3">
          <label class="visually-hidden" for="plan-id">Plan name</label>
          <select
            id="plan-id"
            v-model="plan_id"
            class="form-control"
            multiple
          />
        </div>
        <div class="col-md-6 mt-3">
          <label class="visually-hidden" for="status">Plan status</label>
          <select id="status" v-model="status" class="form-control" multiple />
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 mt-3">
          <label class="visually-hidden" for="min-total-days"
            >Min total no of days</label
          >
          <input
            id="min-total-days"
            v-model="min_total_days"
            type="search"
            class="form-control"
            placeholder="Min total no of days"
          />
        </div>
        <div class="col-md-3 mt-3">
          <label class="visually-hidden" for="max-total-days"
            >Max total no of days</label
          >
          <input
            id="max-total-days"
            v-model="max_total_days"
            type="search"
            class="form-control"
            placeholder="Max total no of days"
          />
        </div>
        <div class="col-md-3 mt-3">
          <label class="visually-hidden" for="min-pending-days"
            >Min pending days</label
          >
          <input
            id="min-pending-days"
            v-model="min_pending_days"
            type="search"
            class="form-control"
            placeholder="Min pending days"
          />
        </div>
        <div class="col-md-3 mt-3">
          <label class="visually-hidden" for="max-pending-days"
            >Max pending days</label
          >
          <input
            id="max-pending-days"
            v-model="max_pending_days"
            type="search"
            class="form-control"
            placeholder="Max pending days"
          />
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-auto mt-3">Plan start date between</div>
        <div class="col-md-auto col-12 mt-3">
          <label class="visually-hidden" for="start-date-from"
            >Plan start date from</label
          >
          <flat-pickr
            id="start-date-from"
            v-model="startDate.from"
            class="form-control"
            placeholder="Plan start date from"
            :config="configs.startDate.from"
            @on-change="onStartDateFromChange"
          />
        </div>
        <div class="col-md-auto col-12 mt-3">and</div>
        <div class="col-md-auto col-12 mt-3">
          <label class="visually-hidden" for="end-date-from"
            >Plan start date until</label
          >
          <flat-pickr
            id="start-date-until"
            v-model="startDate.until"
            class="form-control"
            placeholder="Plan start date until"
            :config="configs.startDate.until"
            @on-change="onStartDateUntilChange"
          />
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-auto mt-3">Plan end date between</div>
        <div class="col-md-auto col-12 mt-3">
          <label class="visually-hidden" for="end-date-from"
            >Plan end date from</label
          >
          <flat-pickr
            id="end-date-from"
            v-model="endDate.from"
            class="form-control"
            placeholder="Plan end date from"
            :config="configs.endDate.from"
            @on-change="onEndDateFromChange"
          />
        </div>
        <div class="col-md-auto col-12 mt-3">and</div>
        <div class="col-md-auto col-12 mt-3">
          <label class="visually-hidden" for="end-date-until"
            >Plan end date until</label
          >
          <flat-pickr
            id="end-date-until"
            v-model="endDate.until"
            class="form-control"
            placeholder="Plan end date until"
            :config="configs.endDate.until"
            @on-change="onEndDateUntilChange"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 mt-3">
          <label class="visually-hidden" for="client-name">Client name</label>
          <input
            id="client-name"
            v-model="clientName"
            type="search"
            class="form-control"
            placeholder="Client name"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-3">
          <button
            class="btn btn-outline-success btn-sm mb-0"
            @click="tableDraw"
          >
            Filter
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table
          id="list-table"
          class="table align-items-center mb-0 hover"
          width="100%"
        >
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Plan name
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Client name
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Plan start date
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Plan end date
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Client plan status
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Total no of days
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Pending days
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Action
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import API from "@/services/api";
import { showMessage } from "@/assets/js/show-message";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { formatDataToChoicesJs, initChoices } from "@/assets/js/init-choices";
import apiMiscList from "@/services/apiMiscList";
import { handleError } from "@/lib/helpers";

export default {
  name: "ListTable",
  components: {
    flatPickr,
  },
  data() {
    return {
      plansChoices: [],
      plan_id: [],
      status: [],
      clientName: "",
      startDate: {
        from: "",
        until: "",
      },
      endDate: {
        from: "",
        until: "",
      },
      min_total_days: "",
      max_total_days: "",
      min_pending_days: "",
      max_pending_days: "",
      configs: {
        startDate: {
          from: {
            maxDate: null,
          },
          until: {
            minDate: null,
          },
        },
        endDate: {
          from: {
            maxDate: null,
          },
          until: {
            minDate: null,
          },
        },
      },
    };
  },
  async mounted() {
    await this.setPlansChoices();
    await this.initPlansChoices();
    await this.initPlanStatusChoices();
    const appInstance = this;
    $.fn.dataTable.ext.classes.sWrapper =
      "dataTables_wrapper dataTable-wrapper";
    $.fn.DataTable.ext.pager.numbers_length = 5;
    $("#list-table").DataTable({
      serverSide: true,
      pagingType: "simple_numbers",
      language: {
        paginate: {
          previous: "‹",
          next: "›",
        },
      },
      order: [],
      columns: [
        {
          data: null,
          render: function (data) {
            if (appInstance.$ability.can("view", "client_plan_details")) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm"><a href="' +
                appInstance.$router.resolve({
                  name: "Client Plan",
                  params: {
                    id: data["client.id"],
                    planId: data.id,
                  },
                }).href +
                '" class="mx-3">' +
                data["planVariant.name"] +
                "</a></h6></div> "
              );
            }
            return (
              '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
              data["planVariant.name"] +
              "</h6></div>"
            );
          },
          sortable: false,
        },
        {
          data: "client\\.name",
          render: function (data) {
            return (
              '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
              data +
              "</h6></div>"
            );
          },
          sortable: false,
        },
        {
          data: "start_date",
          render: function (data) {
            return '<p class="text-secondary mb-0 text-sm">' + data + "</p>";
          },
          className: "align-middle text-center text-sm",
        },
        {
          data: "actual_end_date",
          render: function (data) {
            return '<p class="text-secondary mb-0 text-sm">' + data + "</p>";
          },
          className: "align-middle text-center text-sm",
        },
        {
          data: "status",
          render: function (data) {
            return (
              '<span class="badge badge-dot me-4"><i class="' +
              (data == "Approved" ? "bg-success" : "bg-warning") +
              '"></i><span class="text-dark text-xs">' +
              data +
              "</span></span>"
            );
          },
          className: "align-middle text-center text-sm",
          sortable: false,
        },
        {
          data: "total_days",
          render: function (data) {
            return '<p class="text-secondary mb-0 text-sm">' + data + "</p>";
          },
          className: "align-middle text-center text-sm",
        },
        {
          data: "pending_days",
          render: function (data) {
            return '<p class="text-secondary mb-0 text-sm">' + data + "</p>";
          },
          className: "align-middle text-center text-sm",
        },
        {
          data: null,
          sortable: false,
          render: function (data) {
            let button = "";
            let aClass = " text-dark";
            if (data.status === "Approved") {
              aClass = " text-secondary";
            }
            if (appInstance.$ability.can("view", "client_plan_details")) {
              button +=
                '<a href="' +
                appInstance.$router.resolve({
                  name: "Client Plan",
                  params: {
                    id: data["client.id"],
                    planId: data.id,
                  },
                }).href +
                '" class="mx-3">' +
                '<i class="fas fa-edit' +
                aClass +
                '"></i>' +
                "</a> ";
            }
            return button;
          },
          className: "align-middle text-center text-sm",
        },
      ],
      ajax: function (data, callback) {
        data.columns[1].data = "clients.name";
        data.filter = {};
        if (appInstance.startDate.from.length > 0) {
          data.filter.start_date_after = appInstance.startDate.from;
        }
        if (appInstance.startDate.until.length > 0) {
          data.filter.start_date_before = appInstance.startDate.until;
        }
        if (appInstance.endDate.from.length > 0) {
          data.filter.end_date_after = appInstance.endDate.from;
        }
        if (appInstance.endDate.until.length > 0) {
          data.filter.end_date_before = appInstance.endDate.until;
        }
        if (appInstance.plan_id.length > 0) {
          data.filter.plan_id = appInstance.plan_id;
        }
        if (appInstance.status.length > 0) {
          data.filter.status = appInstance.status;
        }
        if (appInstance.clientName.length > 0) {
          data.filter["clients.name"] = appInstance.clientName;
        }
        if (appInstance.min_total_days > 0) {
          data.filter["min_total_days"] = appInstance.min_total_days;
        }
        if (appInstance.max_total_days > 0) {
          data.filter["max_total_days"] = appInstance.max_total_days;
        }
        if (appInstance.min_pending_days > 0) {
          data.filter["min_pending_days"] = appInstance.min_pending_days;
        }
        if (appInstance.max_pending_days > 0) {
          data.filter["max_pending_days"] = appInstance.max_pending_days;
        }
        API.getClientsPlans($.param(data))
          .then((res) => {
            callback(res.data);
          })
          .catch((err) => {
            const response = API.handleError(err);
            showMessage(response.message, "error");
          })
          .finally(() => {});
      },
    });
    $(document).on("click", "#filter", function () {
      $("#list-table").DataTable().draw();
    });
  },
  beforeUnmount() {
    $("#list-table").DataTable().destroy();
  },
  methods: {
    tableDraw() {
      $("#list-table").DataTable().draw();
    },
    onStartDateFromChange(selectedDates, dateStr) {
      this.configs.startDate.until.minDate = dateStr;
    },
    onStartDateUntilChange(selectedDates, dateStr) {
      this.configs.startDate.from.maxDate = dateStr;
    },
    onEndDateFromChange(selectedDates, dateStr) {
      this.configs.endDate.until.minDate = dateStr;
    },
    onEndDateUntilChange(selectedDates, dateStr) {
      this.configs.endDate.from.maxDate = dateStr;
    },
    async setPlansChoices() {
      await API.getPlans("start=0&length=-1")
        .then((res) => {
          this.plansChoices = API.formatDataToChoicesJs(
            res.data.data,
            "no empty option"
          );
        })
        .catch((err) => {
          const response = API.handleError(err);
          showMessage(response.message, "error");
        });
    },
    async initPlansChoices() {
      const id = "plan-id";
      await initChoices(id, {
        choices: this.plansChoices,
        placeholder: true,
        placeholderValue: "Select plan",
        duplicateItemsAllowed: false,
      });
    },
    async initPlanStatusChoices() {
      const id = "status";
      const clientPlanStatus = await apiMiscList
        .clientPlanStatus()
        .catch(handleError);
      await initChoices(id, {
        choices: formatDataToChoicesJs(
          clientPlanStatus.data.data,
          "Select plan status",
          "enum"
        ),
        placeholder: true,
        placeholderValue: "Select plan status",
        duplicateItemsAllowed: false,
      });
    },
  },
};
</script>
