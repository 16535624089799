<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div
        class="text-right col-lg-12 d-flex flex-column justify-content-center"
      >
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <router-link
            type="button"
            class="btn btn-white text-success"
            :to="{
              name: 'Client Profile',
              params: {
                id: client.id,
              },
            }"
          >
            Back
          </router-link>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="mt-4 col-lg-12 mt-lg-0">
        <div class="card">
          <div class="card-body">
            <div v-show="!loading.consultationsModal" class="modal-body">
              <div class="row">
                <div class="col-12 col-md-6 mt-3">
                  <label>Weight</label>
                  <input
                    id="weight"
                    v-model="consultations.weight"
                    class="form-control"
                    placeholder="Weight"
                    type="number"
                    min="0"
                  />
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Skeletal Muscle Mass (in KGs)</label>
                  <input
                    id="skeletal_muscle_mass"
                    v-model="consultations.skeletal_muscle_mass"
                    class="form-control"
                    placeholder="Skeletal Muscle Mass"
                    type="number"
                    min="0"
                  />
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Body Fat Percentage (in KGs)</label>
                  <input
                    id="body_fat_percentage"
                    v-model="consultations.body_fat_percentage"
                    class="form-control"
                    placeholder="Body Fat Percentage"
                    type="number"
                    min="0"
                  />
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Basal Metabolic Ratio</label>
                  <input
                    id="basal_metabolic_ratio"
                    v-model="consultations.basal_metabolic_ratio"
                    class="form-control"
                    placeholder="basal Metabolic Ratio"
                    type="number"
                    min="0"
                  />
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Body Mass Index</label>
                  <input
                    id="body_mass_index"
                    v-model="consultations.body_mass_index"
                    class="form-control"
                    placeholder="Body Mass Index"
                    type="number"
                    min="0"
                  />
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Client Plan</label>
                  <select
                    id="client_plan_detail_id"
                    v-model="consultations.client_plan_detail_id"
                    name="client_plan_detail_id"
                    class="form-control"
                  ></select>
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Goal</label>
                  <select
                    id="goal"
                    v-model="consultations.goal"
                    name="goal"
                    class="form-control"
                  ></select>
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Payable Amount</label>
                  <input
                    id="payable_amount"
                    v-model="consultations.payable_amount"
                    class="form-control"
                    placeholder="Payable Amount"
                    type="number"
                    min="0"
                  />
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Result Status</label>
                  <select
                    id="result_status"
                    v-model="consultations.result_status"
                    name="result_status"
                    class="form-control"
                  ></select>
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Private Status</label>
                  <select
                    id="private_status"
                    v-model="consultations.private_status"
                    name="private_status"
                    class="form-control"
                  ></select>
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Mode</label>
                  <select
                    id="mode"
                    v-model="consultations.mode"
                    name="mode"
                    class="form-control"
                  ></select>
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <argon-date
                    id="form-consultation-date"
                    v-model="consultations.consultation_date"
                    label="Consultation Date"
                    placeholder="Select date"
                    name="consultation_date"
                    :config="consultationDateConfig"
                  />
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Activity level </label>
                  <select
                    id="activity_level"
                    v-model="consultations.extra.activity_level"
                    name="activity_level"
                    class="form-control"
                  ></select>
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Beverages intake </label>
                  <input
                    id="beverages_intake"
                    v-model="consultations.extra.beverages_intake"
                    class="form-control"
                    placeholder="Beverages intake"
                  />
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <argon-textarea
                    id="supplements"
                    placeholder=""
                    :model-value="consultations.extra.supplements"
                    @update:model-value="consultations.supplements = $event"
                    >Supplements/Medication
                  </argon-textarea>
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <argon-textarea
                    id="medical-condition"
                    placeholder=""
                    :model-value="consultations.extra.medical_condition"
                    @update:model-value="
                      consultations.medical_condition = $event
                    "
                    >Medical Condition
                  </argon-textarea>
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <argon-textarea
                    id="plan-notes"
                    placeholder=""
                    :model-value="consultations.notes"
                    @update:model-value="consultations.notes = $event"
                    >Notes
                  </argon-textarea>
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <argon-textarea
                    id="plan-private-notes"
                    placeholder=""
                    :model-value="consultations.private_notes"
                    @update:model-value="consultations.private_notes = $event"
                    >Private notes
                  </argon-textarea>
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <consultation-file-upload
                    :existing-plan-images="consultations.inbody_sheet"
                    :clear-files="clearFiles"
                    :delete-option="true"
                    card-label="Please Upload the Inbody Sheet"
                    @update:new-file-update="updateInbodySheet"
                    @delete:file="removeExistingFile"
                  />
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <consultation-file-upload
                    :existing-plan-images="consultations.extra_doc"
                    :clear-files="clearFiles"
                    :delete-option="true"
                    card-label="Extra Documents"
                    @update:new-file-update="updateExtraDocument"
                    @delete:file="removeExistingFile"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div
        class="text-right col-lg-12 d-flex flex-column justify-content-center"
      >
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <router-link
            class="btn mb-0 bg-gradient-secondary btn-sm"
            :to="{
              name: 'Client Profile',
              params: {
                id: client.id,
              },
            }"
          >
            Back
          </router-link>

          <button
            type="button"
            class="btn mb-0 bg-gradient-success btn-sm ms-3"
            @click="saveConsultation"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { handleError } from "@/lib/helpers";
import ArgonTextarea from "@/components/ArgonTextarea.vue";
import ArgonDate from "@/components/ArgonDate.vue";
import { initChoices } from "@/assets/js/init-choices";
import { showMessage, showConfirmation } from "@/assets/js/show-message";
import apiMiscList from "@/services/apiMiscList";
import API from "@/services/api";
import ConsultationFileUpload from "@/views/pages/Clients/components/ConsultationFileUpload";
import moment from "moment";
import { dateFormat } from "@/lib/dateHelper";
export default {
  name: "EditConsultation",
  components: {
    ArgonTextarea,
    ArgonDate,
    ConsultationFileUpload,
  },
  data() {
    return {
      client: {
        id: this.$route.params.id,
        first_name: "",
        last_name: "",
        email: "",
        mobile_number: "",
        gender: "",
        birth_date: "",
        bio: "",
        profile_picture: "",
        height: "",
        weight: "",
        goal_weight: "",
        dislikes: [],
        dislikeExclusions: [],
        plans: [],
        walletTransactions: [],
        invoices: [],
        addresses: [],
        wallets: [],
        consultations: [],
      },
      inbody_sheet: [],
      extra_doc: [],
      consultationId: this.$route.params.consultationId,
      consultations: {
        extra: {
          medical_condition: "",
          supplements: "",
          activity_level: "",
          beverages_intake: "",
        },
      },
      loading: {},
      formSubmitted: {},
      clientPlanList: [],
      clearFiles: false,
      consultationDateConfig: {
        maxDate: dateFormat("today", "YYYY-MM-DD"),
      },
    };
  },

  async mounted() {
    this.setClientPlans();
    this.getConsultationsDetrails();
  },
  methods: {
    async getConsultationsDetrails() {
      const appInstance = this;
      await API.getConsultationsDetrails(this.client.id, this.consultationId)
        .then((res) => {
          this.client.consultations = [];
          this.clearFiles = false;
          if (res.data) {
            appInstance.loading.consultationsModal = false;
            this.consultations = res.data.data;
            this.consultations.payable_amount =
              res.data.data.payable_amount || 0;
            this.consultations.inbody_sheet =
              res.data.data.media.data.filter(
                (item) => item.type === "inbody_sheet"
              ) || [];
            this.consultations.extra_doc =
              res.data.data.media.data.filter(
                (item) => item.type === "consultations"
              ) || [];
            if (!this.consultations.extra) {
              this.consultations.extra = {
                medical_condition: "",
                supplements: "",
                activity_level: "",
                beverages_intake: "",
              };
            }
            initChoices(
              "goal",
              {
                choices: [
                  { label: "Weight loss", value: "weight_loss" },
                  { label: "Muscle Gain", value: "muscle_gain" },
                  { label: "Weight Maintain", value: "weight_maintain" },
                  {
                    label: "Body Composition Change",
                    value: "body_transformation",
                  },
                  { label: "Health Focused", value: "improve_health" },
                  { label: "Health Focused", value: "improve_health" },
                  {
                    label: "Post Natal Recovery",
                    value: "post_natal_recovery",
                  },
                ],
                searchEnabled: false,
                shouldSort: false,
              },
              res.data.data.goal,
              res.data.data.goal
            );
            initChoices(
              "result_status",
              {
                choices: [
                  { label: "Negative", value: "Negative" },
                  { label: "Positive", value: "Positive" },
                  { label: "Neutral", value: "Neutral" },
                ],
                searchEnabled: false,
                shouldSort: false,
              },
              res.data.data.result_status,
              res.data.data.result_status
            );
            initChoices(
              "private_status",
              {
                choices: [
                  { label: "Negative", value: "Negative" },
                  { label: "Positive", value: "Positive" },
                  { label: "Neutral", value: "Neutral" },
                ],
                searchEnabled: false,
                shouldSort: false,
              },
              res.data.data.private_status,
              res.data.data.private_status
            );
            initChoices(
              "mode",
              {
                choices: [
                  { label: "Physical", value: "Physical" },
                  { label: "Phone Call", value: "Phone Call" },
                  { label: "Video Conference", value: "Video Conference" },
                ],
                searchEnabled: false,
                shouldSort: false,
              },
              res.data.data.mode,
              res.data.data.mode
            );

            initChoices(
              "client_plan_detail_id",
              {
                choices: this.clientPlanList,
                searchEnabled: true,
                shouldSort: false,
              },
              res.data.data.client_plan_detail_id,
              res.data.data.client_plan_detail_id
            );
            initChoices(
              "activity_level",
              {
                choices: apiMiscList.activityLevelChoices(),
                searchEnabled: false,
                shouldSort: false,
              },
              res.data.data.extra.activity_level
            );
          }
        })
        .catch((err) => {
          const response = handleError(err);
          appInstance.loading.consultationsModal = false;
          showMessage(response.message, "error");
        });
    },
    updateInbodySheet(newFiles) {
      this.inbody_sheet = newFiles;
    },
    updateExtraDocument(newFiles) {
      this.extra_doc = newFiles;
    },
    async setClientPlans() {
      await API.getClientPlanDetails(this.client.id)
        .then((res) => {
          this.client.plans = [];
          let choices = [];

          let sortedData = res.data.data.sort(function (a, b) {
            return new Date(b.start_date) - new Date(a.start_date);
          });

          for (let i in sortedData) {
            let start = moment(sortedData[i]["start_date"]);
            let end = moment(sortedData[i]["actual_end_date"]);
            this.client.plans.push({
              image: sortedData[i]["image"],
              name: sortedData[i]["plan.name"],
              id: sortedData[i]["id"],
              start_date: start.format("D MMM YYYY"),
              actual_end_date: end.format("D MMM YYYY"),
              total_days: sortedData[i]["total_days"],
              remaining_days: sortedData[i].pending_days,
              status: sortedData[i].status,
            });
            choices.push({
              label:
                sortedData[i]["plan.name"] +
                " ( " +
                moment(sortedData[i]["start_date"]).format("D MMM YYYY") +
                " )",
              value: sortedData[i]["id"],
            });
          }

          this.clientPlanList = choices;
          initChoices(
            "client_plan_detail_id",
            {
              choices: this.clientPlanList,
              searchEnabled: false,
              shouldSort: false,
            },
            this.consultations.client_plan_detail_id,
            this.consultations.client_plan_detail_id
          );
        })
        .catch((err) => {
          const response = handleError(err);
          showMessage(response.message, "error");
        });
    },
    async removeExistingFile(file) {
      const confirmation = await showConfirmation({
        title: `Are you sure you want to delete  ${file.name} file?`,
      });
      if (!confirmation) {
        return;
      }
      const response = await API.deleteConsultationAttachment(
        this.client.id,
        this.consultations.id,
        file.id
      ).catch(handleError);
      if (response.status === 200) {
        this.getConsultationsDetrails();
        showMessage(response.data.message, "success");
      } else {
        showMessage(response.message, "error");
      }
    },
    async saveConsultation() {
      if (this.formSubmitted.saveConsultation) {
        showMessage("Saving data. Please wait.", "", 1500);
        return;
      }
      this.formSubmitted.saveConsultation = true;
      let formData = new FormData();
      Object.keys(this.consultations).forEach((key) => {
        if ((key !== "extra", key !== "inbody_sheet")) {
          formData.append(key, this.consultations[key]);
        }
      });
      if (this.inbody_sheet.length > 0) {
        formData.append("inbody_sheet", this.inbody_sheet[0].file);
      }
      this.extra_doc.forEach((item, index) => {
        formData.append(`attachments[${index}]`, item.file);
      });
      formData.append(
        "extra[medical_condition]",
        this.consultations.extra.medical_condition
      );
      formData.append(
        "extra[supplements]",
        this.consultations.extra.supplements
      );
      formData.append(
        "extra[activity_level]",
        this.consultations.extra.activity_level
      );
      formData.append(
        "extra[beverages_intake]",
        this.consultations.extra.beverages_intake
      );

      let saveResponse = null;
      let message = null;

      formData.set("_method", "PATCH");
      saveResponse = await API.updateConsultations(
        this.client.id,
        this.consultations.id,
        formData
      ).catch(handleError);

      let messageTimer = 2500;

      if (saveResponse.status === 200) {
        this.getConsultationsDetrails();
        this.clearFiles = true;
        message =
          '<i class="fas fa-check-circle text-md ms-3 text-success"></i> <span>' +
          saveResponse.data.message +
          "</span>";
      } else {
        message =
          '<i class="fas fa-exclamation-triangle text-md ms-3 text-danger"></i> <span>' +
          saveResponse.message +
          "</span>";
        messageTimer = 3500;
      }
      showMessage(message, "", messageTimer, "", "html");
      this.formSubmitted.saveConsultation = false;
    },
  },
};
</script>
