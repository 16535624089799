<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div
        class="text-right col-lg-12 d-flex flex-column justify-content-center"
      >
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <router-link
            type="button"
            class="btn btn-white text-success"
            :to="{
              name: 'Client Profile',
              params: {
                id: client.id,
              },
            }"
          >
            Back
          </router-link>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="mt-4 col-lg-12 mt-lg-0">
        <div class="card">
          <div class="card-body">
            <div v-show="!loading.consultationsModal" class="modal-body">
              <div class="row">
                <div class="col-12 col-md-6 mt-3">
                  <label>Weight</label>
                  <input
                    id="weight"
                    v-model="consultations.weight"
                    disabled
                    class="form-control"
                    placeholder="Weight"
                    type="number"
                  />
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Skeletal Muscle Mass (in KGs)</label>
                  <input
                    id="skeletal_muscle_mass"
                    v-model="consultations.skeletal_muscle_mass"
                    disabled
                    class="form-control"
                    placeholder="Skeletal Muscle Mass"
                    type="number"
                  />
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Body Fat Percentage (in KGs)</label>
                  <input
                    id="body_fat_percentage"
                    v-model="consultations.body_fat_percentage"
                    disabled
                    class="form-control"
                    placeholder="Body Fat Percentage"
                    type="number"
                  />
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Basal Metabolic Ratio</label>
                  <input
                    id="basal_metabolic_ratio"
                    v-model="consultations.basal_metabolic_ratio"
                    disabled
                    class="form-control"
                    placeholder="basal Metabolic Ratio"
                    type="number"
                  />
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Body Mass Index</label>
                  <input
                    id="body_mass_index"
                    v-model="consultations.body_mass_index"
                    disabled
                    class="form-control"
                    placeholder="Body Mass Index"
                    type="number"
                  />
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Client Plan Detail Id</label>
                  <input
                    id="client_plan_detail_id"
                    v-model="consultations.client_plan_detail_id"
                    disabled
                    class="form-control"
                    placeholder="Client Plan Detail Id"
                    type="number"
                  />
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Goal</label>
                  <div class="form-group">
                    <select
                      id="goal"
                      v-model="consultations.goal"
                      disabled
                      name="goal"
                      class="form-control"
                    ></select>
                  </div>
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Payable Amount</label>
                  <input
                    id="payable_amount"
                    v-model="consultations.payable_amount"
                    disabled
                    class="form-control"
                    placeholder="Payable Amount"
                    type="number"
                  />
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Result Status</label>
                  <select
                    id="result_status"
                    v-model="consultations.result_status"
                    disabled
                    name="result_status"
                    class="form-control"
                  ></select>
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Private Status</label>
                  <select
                    id="private_status"
                    v-model="consultations.private_status"
                    disabled
                    name="private_status"
                    class="form-control"
                  ></select>
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Mode</label>
                  <select
                    id="mode"
                    v-model="consultations.mode"
                    disabled
                    name="mode"
                    class="form-control"
                  ></select>
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Consultation Date</label>
                  <flat-pickr
                    v-model="consultations.consultation_date"
                    class="form-control"
                    disabled
                    placeholder="Select date"
                    name="consultation_date"
                  />
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Activity level </label>
                  <select
                    id="activity_level"
                    v-model="consultations.extra.activity_level"
                    disabled
                    name="activity_level"
                    class="form-control"
                  ></select>
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Beverages intake </label>
                  <input
                    id="beverages_intake"
                    v-model="consultations.extra.beverages_intake"
                    disabled
                    class="form-control"
                    placeholder="Beverages intake"
                  />
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <textarea
                    id="supplements"
                    v-model="consultations.extra.supplements"
                    placeholder=""
                    :disabled="true"
                    class="form-control"
                  >
                  </textarea>
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <textarea
                    id="medical-condition"
                    v-model="consultations.extra.medical_condition"
                    placeholder=""
                    :disabled="true"
                    class="form-control"
                  >
                  </textarea>
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Notes</label>
                  <textarea
                    id="plan-notes"
                    v-model="consultations.notes"
                    :disabled="true"
                    placeholder=""
                    class="form-control"
                  >
                  </textarea>
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Private notes</label>
                  <textarea
                    id="plan-private-notes"
                    v-model="consultations.private_notes"
                    :disabled="true"
                    placeholder=""
                    class="form-control"
                  >
                  </textarea>
                </div>
                <div
                  v-if="consultations.inbody_sheet?.length"
                  class="col-12 col-md-6 mt-3"
                >
                  <consultation-file-upload
                    :view-only="true"
                    :existing-plan-images="consultations.inbody_sheet"
                    card-label="Inbody Sheet"
                  />
                </div>
                <div
                  v-if="consultations.extra_doc?.length"
                  class="col-12 col-md-6 mt-3"
                >
                  <consultation-file-upload
                    :view-only="true"
                    :existing-plan-images="consultations.extra_doc"
                    card-label="Extra Documents"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div
        class="text-right col-lg-12 d-flex flex-column justify-content-center"
      >
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <router-link
            class="btn mb-0 bg-gradient-secondary btn-sm"
            :to="{
              name: 'Client Profile',
              params: {
                id: client.id,
              },
            }"
          >
            Back
          </router-link>
          <router-link
            v-if="$can('update', 'consultations')"
            class="btn mb-0 bg-gradient-success btn-sm ms-3"
            :to="{
              name: 'Edit Consultation',
              params: {
                id: client.id,
                consultationId: consultationId,
              },
            }"
          >
            Edit
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { handleError } from "@/lib/helpers";
import { initChoices } from "@/assets/js/init-choices";
import { showMessage } from "@/assets/js/show-message";
import flatPickr from "vue-flatpickr-component";
import apiMiscList from "@/services/apiMiscList";
import ConsultationFileUpload from "@/views/pages/Clients/components/ConsultationFileUpload";
import API from "@/services/api";
export default {
  name: "ConsultationsView",
  components: {
    flatPickr,
    ConsultationFileUpload,
  },
  data() {
    return {
      client: {
        id: this.$route.params.id,
        first_name: "",
        last_name: "",
        email: "",
        mobile_number: "",
        gender: "",
        birth_date: "",
        bio: "",
        profile_picture: "",
        height: "",
        weight: "",
        goal_weight: "",
        dislikes: [],
        dislikeExclusions: [],
        plans: [],
        walletTransactions: [],
        invoices: [],
        addresses: [],
        wallets: [],
        consultations: [],
      },
      consultationId: this.$route.params.consultationId,
      consultations: {
        extra: {
          medical_condition: "",
          supplements: "",
          activity_level: "",
          beverages_intake: "",
        },
      },
      loading: {},
      formSubmitted: {},
    };
  },
  async mounted() {
    this.getConsultationsDetrails();
  },
  methods: {
    async getConsultationsDetrails() {
      const appInstance = this;
      await API.getConsultationsDetrails(this.client.id, this.consultationId)
        .then((res) => {
          this.client.consultations = [];
          if (res.data) {
            appInstance.loading.consultationsModal = false;
            this.consultations = res.data.data;
            this.consultations.inbody_sheet =
              res.data.data.media.data.filter(
                (item) => item.type === "inbody_sheet"
              ) || [];
            this.consultations.extra_doc =
              res.data.data.media.data.filter(
                (item) => item.type === "consultations"
              ) || [];
            if (!this.consultations.extra) {
              this.consultations.extra = {
                medical_condition: "",
                supplements: "",
                activity_level: "",
                beverages_intake: "",
              };
            }
            initChoices(
              "goal",
              {
                choices: [
                  { label: "Weight loss", value: "Weight loss" },
                  { label: "Weight Gain", value: "Weight Gain" },
                  { label: "Weight Maintain", value: "Weight Maintain" },
                  {
                    label: "Body Composition Change",
                    value: "Body Composition Change",
                  },
                  { label: "Health Focused", value: "Health Focused" },
                ],
                searchEnabled: true,
              },
              res.data.data.goal,
              res.data.data.goal
            );
            initChoices(
              "result_status",
              {
                choices: [
                  { label: "Negative", value: "Negative" },
                  { label: "Positive", value: "Positive" },
                  { label: "Neutral", value: "Neutral" },
                ],
                searchEnabled: true,
              },
              res.data.data.result_status,
              res.data.data.result_status
            );
            initChoices(
              "private_status",
              {
                choices: [
                  { label: "Negative", value: "Negative" },
                  { label: "Positive", value: "Positive" },
                  { label: "Neutral", value: "Neutral" },
                ],
                searchEnabled: true,
              },
              res.data.data.private_status,
              res.data.data.private_status
            );
            initChoices(
              "mode",
              {
                choices: [
                  { label: "Physical", value: "Physical" },
                  { label: "Phone Call", value: "Phone Call" },
                  { label: "Video Conference", value: "Video Conference" },
                ],
                searchEnabled: true,
              },
              res.data.data.mode,
              res.data.data.mode
            );
            initChoices(
              "activity_level",
              {
                choices: apiMiscList.activityLevelChoices(),
                searchEnabled: false,
              },
              res.data.data.extra.activity_level
            );
          }
        })
        .catch((err) => {
          const response = handleError(err);
          appInstance.loading.consultationsModal = false;
          showMessage(response.message, "error");
        });
    },
  },
};
</script>
