<template>
  <div
    id="menu-modal"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="menu-modal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            v-if="show.info && menuItem.recipe"
            class="modal-title"
            :class="{
              'text-disliked': isDisliked(
                menuItem.recipe.data.id,
                menuItem.recipe.data.client_recipe_dislikes
              ),
            }"
          >
            {{ menuItem.recipe.data.name }}
          </h5>
          <h5 v-if="show.form" class="modal-title">Select new menu item</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <loading-spinner
          :loading="loading.menuModal || (show.form && loading.recipes)"
        />
        <div v-if="!loading.menuModal" class="modal-body">
          <div v-show="show.form && !loading.recipes" class="row">
            <div class="col-12">
              <div class="form-group">
                <label>Recipe</label>
                <select
                  id="select-recipe"
                  v-model="selectedMenuItemId"
                  class="form-control"
                ></select>
              </div>
            </div>
            <div class="col-12">
              <radio-buttons
                v-model="recipeType"
                :options="recipeTypeOptions"
                class="d-flex flex-wrap g-3"
                @change="handleRecipeTypeChange"
              />
            </div>
          </div>
          <div v-if="show.form && recipe.name" class="row mt-3">
            <div class="col-12 mb-2">
              <popper-copy-text
                :text="recipe.name"
                :class="{
                  'text-disliked': isDisliked(
                    recipe.id,
                    recipe.client_recipe_dislikes
                  ),
                }"
                ><strong>{{ recipe.name }}</strong></popper-copy-text
              >
            </div>
          </div>

          <div
            v-show="show.info || (show.form && selectedMenuItemId)"
            class="row"
          >
            <div class="col-12">
              <strong class="mb-1">Allergens: </strong>
              <template v-for="(el, index) in recipe.allergens" :key="index">
                <span
                  class="badge ms-1"
                  :class="
                    isClientAllergen(el.id, recipe.client_allergens) ||
                    isClientAllergen(el.id, recipe.client_ingredient_allergens)
                      ? 'badge-danger'
                      : 'badge-success '
                  "
                  >{{ el["name"] }}</span
                >
              </template>
            </div>
            <div class="col-12 mt-3">
              <strong class="mb-1">Food groups: </strong>
              <template v-for="(el, index) in recipeFoodGroups" :key="index">
                <span
                  class="badge ms-1"
                  :class="
                    isClientFoodGroupDisliked(
                      el.food_group_id,
                      recipe.client_food_group_dislikes
                    )
                      ? 'bg-yellow'
                      : 'badge-success'
                  "
                  >{{ el["food_group.name"] }}</span
                >
              </template>
            </div>
            <div class="col-6 mt-3">
              <strong class="mb-1">Ingredients: </strong>
              <div
                v-for="(el, index) in recipeIngredients"
                :key="index"
                class="d-flex"
              >
                <span
                  class="my-auto text-sm"
                  :class="{
                    'text-disliked':
                      isDisliked(
                        el.ingredient_id,
                        recipe.client_ingredient_dislikes
                      ) &&
                      !isClientAllergen(
                        el.ingredient_id,
                        recipe.client_ingredient_allergens
                      ),
                    'text-danger': isClientAllergen(
                      el.ingredient_id,
                      recipe.client_ingredient_allergens
                    ),
                  }"
                >
                  {{ el["ingredient.name"] }}
                </span>

                <div v-if="el.removable" class="my-auto form-check ms-3">
                  <input
                    :id="`removed-ingredient-${index}`"
                    v-model="removedIngredients"
                    type="checkbox"
                    class="form-check-input"
                    :class="el.removable ? '' : 'opacity-6'"
                    :value="el.id"
                    :disabled="!el.removable"
                  />
                </div>
                <label
                  v-if="el.removable"
                  class="my-auto"
                  :class="el.removable ? '' : 'opacity-6'"
                  :for="`removed-ingredient-${index}`"
                  >Delete</label
                >
              </div>
            </div>
            <div v-if="recipe.image" class="col-6 mt-3">
              <img class="h-100 w-100 img-contain" :src="recipe.image" alt="" />
            </div>
            <div class="col-12 mt-3">
              <strong class="mb-1">Menu item customizations: </strong>
              <textarea
                v-model="menuItem.customizations"
                name="customizations"
                class="form-control"
              ></textarea>
            </div>
            <div v-show="show.info" class="col-12 mt-3">
              <recipe-macros-badge
                v-if="menuItem['recipe.macros']"
                :calories="menuItem['recipe.macros'].calories"
                :carbs="menuItem['recipe.macros'].carbs"
                :fat="menuItem['recipe.macros'].fat"
                :protein="menuItem['recipe.macros'].protein"
              />
            </div>
            <div v-show="show.form" class="col-12 mt-3">
              <div class="d-flex">
                <strong class="me-3">New Daily Total:</strong>
                <recipe-macros-badge
                  :calories="newDailyMacros.calories"
                  :carbs="newDailyMacros.carbs"
                  :fat="newDailyMacros.fat"
                  :protein="newDailyMacros.protein"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            v-show="show.info"
            type="button"
            class="btn btn-primary"
            @click="saveMenuCustomizations"
          >
            Save
          </button>
          <button
            v-show="show.info"
            type="button"
            class="btn btn-primary"
            @click="showChangeRecipeForm"
          >
            Change recipe
          </button>
          <button
            v-show="show.form"
            type="button"
            class="btn btn-primary"
            @click="saveSingleMenuItem"
          >
            Save menu item
          </button>
          <button
            v-show="
              !loading.menuModal &&
              menuItem.id > 0 &&
              menuItem.menu_type != 'cycle_menu'
            "
            type="button"
            class="btn btn-primary"
            @click="revertToCycle"
          >
            Revert to Cycle
          </button>

          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDataToChoicesJs, initChoices } from "@/assets/js/init-choices";
import API from "@/services/api";
import ApiSubstituteMenu from "@/services/apiSubstituteMenu";
import ApiClientDailyMenu from "@/services/apiClientDailyMenu";
import ApiRecipes from "@/services/apiRecipes";
import { handleError } from "@/lib/helpers";
import { showMessage } from "@/assets/js/show-message";
import LoadingSpinner from "@/components/LoadingSpinner";
import RecipeMacrosBadge from "@/components/RecipeMacrosBadge";
import RadioButtons from "@/components/RadioButtons";
import PopperCopyText from "@/components/PopperCopyText.vue";
export default {
  name: "PlanMenuModal",
  components: {
    RecipeMacrosBadge,
    LoadingSpinner,
    RadioButtons,
    PopperCopyText,
  },
  props: {
    clientId: {
      type: [Number, String],
      required: true,
    },
    deliveryId: {
      type: [Number, String],
      required: true,
    },
    deliveryDate: {
      type: String,
      required: true,
    },
    planTypeInitial: {
      type: String,
      required: true,
    },
    planTypeChoices: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["saved"],
  data() {
    return {
      show: {
        form: false,
        info: false,
      },
      loading: {
        menuModal: true,
        recipes: true,
      },
      menuItemChoices: [],
      formSubmitted: {
        menu: false,
        menuCustomizations: false,
      },
      selectedMenuItemId: 0,
      selectedMenuItem: {},
      menuItemCode: "",
      menuItem: {},
      dailyMacros: {},
      recipe: {},
      removedIngredients: [],
      planType: null,
      recipeType: "substitute",
      substituteTypes: [],
      selectRecipeId: "select-recipe",
      searchTimeOut: null,
      delivery: null,
    };
  },
  computed: {
    recipeFoodGroups() {
      if (this.recipe.recipeFoodGroups) {
        return this.recipe.recipeFoodGroups.data;
      } else {
        return [];
      }
    },
    recipeIngredients() {
      if (this.recipe.recipeIngredients) {
        return this.recipe.recipeIngredients.data;
      } else {
        return [];
      }
    },
    recipeTypeOptions() {
      let result = [
        {
          value: "all",
          label: "All recipes",
          guard: {
            action: "update_from_all_recipes",
            subject: "client_daily_menus",
          },
        },
      ];
      if (this.menuItem.id) {
        result.push(...this.substituteTypes);
      }
      this.planTypeChoices.forEach((item) => {
        result.push({
          value: item.value,
          label: item.value + " recipes",
        });
      });
      return result;
    },
    newDailyMacros() {
      let macros = {};
      if (this.dailyMacros) {
        macros = Object.assign({}, this.dailyMacros);
      }
      if (this.menuItem["recipe.macros"]) {
        macros.calories =
          macros.calories - parseFloat(this.menuItem["recipe.macros"].calories);
        macros.fat =
          macros.fat - parseFloat(this.menuItem["recipe.macros"].fat);
        macros.carbs =
          macros.carbs - parseFloat(this.menuItem["recipe.macros"].carbs);
        macros.protein =
          macros.protein - parseFloat(this.menuItem["recipe.macros"].protein);
      }
      if (this.selectedMenuItem["recipe.macros"]) {
        macros.calories =
          macros.calories +
          parseFloat(this.selectedMenuItem["recipe.macros"].calories);
        macros.fat =
          macros.fat + parseFloat(this.selectedMenuItem["recipe.macros"].fat);
        macros.carbs =
          macros.carbs +
          parseFloat(this.selectedMenuItem["recipe.macros"].carbs);
        macros.protein =
          macros.protein +
          parseFloat(this.selectedMenuItem["recipe.macros"].protein);
      }
      return macros;
    },
  },
  async mounted() {
    this.planType = this.planTypeInitial;
    this.substituteTypes = [
      {
        value: "substitute",
        label: "Substitute menu recipes",
      },
      {
        value: "substitute_other_plans",
        label: "Substitute menu recipes from other plans",
      },
    ];
    await this.menuModal();
  },
  methods: {
    getMealType() {
      if (this.recipeType == "all") {
        return "all";
      }
      let meal_type = this.menuItemCode.slice(0, 2);
      if (meal_type == "MS" || meal_type == "AS") {
        meal_type = "SN";
      }
      return meal_type;
    },
    async menuModal() {
      let appInstance = this;
      let modalEl = document.getElementById("menu-modal");
      modalEl.addEventListener("hide.bs.modal", async function () {
        appInstance.loading.menuModal = true;
        appInstance.show.info = false;
        appInstance.show.form = false;
      });
      modalEl.addEventListener("shown.bs.modal", async function (event) {
        appInstance.loading.menuModal = true;
        appInstance.menuItem = {
          id: 0,
          client_delivery_id: 0,
        };
        appInstance.selectedMenuItemId = 0;
        appInstance.selectedMenuItem = {};
        appInstance.menuItemCode = null;
        if (event.relatedTarget.dataset.dailyMacros) {
          appInstance.dailyMacros = JSON.parse(
            event.relatedTarget.dataset.dailyMacros
          );
        }
        if (event.relatedTarget.dataset.menuItemCode) {
          appInstance.menuItemCode = event.relatedTarget.dataset.menuItemCode;
        }
        if (event.relatedTarget.dataset.menuItem) {
          appInstance.menuItem = JSON.parse(
            event.relatedTarget.dataset.menuItem
          );
          appInstance.delivery = {
            date: appInstance.menuItem.menu_date,
            id: appInstance.menuItem.client_delivery_id,
          };
          appInstance.removedIngredients =
            appInstance.menuItem.meta !== null &&
            appInstance.menuItem.meta.removed_ingredients
              ? appInstance.menuItem.meta.removed_ingredients
              : [];
          await appInstance.setRecipe(appInstance.menuItem.recipe.data.id);
          appInstance.show.info = true;
          appInstance.show.form = false;
        } else {
          appInstance.delivery = {
            date: appInstance.deliveryDate,
            id: appInstance.deliveryId,
          };
          appInstance.recipeType = appInstance.planTypeChoices[0].value;
          await appInstance.showChangeRecipeForm();
        }
        if (event.relatedTarget.dataset.showForm) {
          await appInstance.showChangeRecipeForm();
        }
        appInstance.loading.menuModal = false;
      });
    },
    async initRecipeChoices() {
      this.loading.recipes = true;
      await this.setRecipeChoices(
        this.clientId,
        this.delivery.id,
        this.menuItem.id,
        this.recipeType
      );
      await initChoices(this.selectRecipeId, {
        choices: this.recipeChoices,
      });
      this.setRecipeEvents(this.selectRecipeId);
      this.loading.recipes = false;
    },
    async setRecipeEvents(selectRecipeId) {
      const el = document.getElementById(selectRecipeId);

      el.addEventListener("change", this.handleChangeRecipe, false);
      if (this.recipeType == "all") {
        el.addEventListener("search", this.handleSearchRecipe, false);
      } else {
        el.removeEventListener("search", this.handleSearchRecipe, false);
      }
    },

    async handleChangeRecipe(event) {
      this.selectedMenuItem = {};
      this.recipe = {};
      if (event.detail.value > 0) {
        this.selectedMenuItem = this.menuItemChoices[this.getMealType()].find(
          (el) => el.id == event.detail.value
        );
        if (this.recipeType == "all") {
          await this.setRecipe(this.selectedMenuItem.id);
        } else {
          await this.setRecipe(this.selectedMenuItem.recipe_id);
        }
      } else {
        this.selectedMenuItemId = 0;
        await initChoices(this.selectRecipeId, {
          choices: this.recipeChoices,
        });
      }
    },
    async handleSearchRecipe(event) {
      clearTimeout(this.searchTimeOut);
      this.searchTimeOut = setTimeout(async () => {
        const response = await this.getRecipes(event.detail.value).catch(
          handleError
        );
        if (response.status === 200) {
          await this.setMenuItemChoices(response, this.recipeType);
          await initChoices(this.selectRecipeId, {
            choices: this.recipeChoices,
          });
        } else {
          showMessage(response.message, "error");
        }
      }, 500);
    },
    async getMenuItems(clientId, deliveryId, menuId, recipeType) {
      let response = { status: 0 };

      if (
        this.substituteTypes.some((type) => type.value === recipeType) &&
        menuId > 0
      ) {
        if (recipeType == "substitute") {
          response = await ApiClientDailyMenu.getClientDeliveryMenuSubstitutes(
            clientId,
            deliveryId,
            menuId
          ).catch(handleError);
        } else if (recipeType == "substitute_other_plans") {
          response =
            await ApiClientDailyMenu.getClientDeliveryMenuSubstitutesOtherPlans(
              clientId,
              deliveryId,
              menuId
            ).catch(handleError);
        }
      } else if (
        this.planTypeChoices.some((type) => type.value === recipeType)
      ) {
        let meal_type = this.getMealType();
        response = await ApiSubstituteMenu.get(
          recipeType,
          this.delivery.date,
          this.delivery.date,
          meal_type
        ).catch(handleError);
      } else {
        response = await ApiRecipes.dropdown("start=0&length=50").catch(
          handleError
        );
      }
      return response;
    },
    async setRecipeChoices(clientId, deliveryId, menuId, recipeType) {
      this.loading.recipes = true;
      this.recipeChoices = [];
      let response = await this.getMenuItems(
        clientId,
        deliveryId,
        menuId,
        recipeType
      );
      if (response.status === 200) {
        await this.setMenuItemChoices(response, recipeType);
      } else {
        showMessage(response.message, "error");
      }
      this.loading.recipes = false;
    },
    async setMenuItemChoices(response, recipeType) {
      this.menuItemChoices = [];
      let nameField = "recipe.name";
      if (recipeType == "all") {
        nameField = "name";
      }
      for (let i in response.data.data) {
        let mealType = response.data.data[i]["meal_type"];
        if (!mealType && response.data.data[i]["meal_code"]) {
          mealType = response.data.data[i]["meal_code"].slice(0, 2);
        } else if (recipeType == "all") {
          mealType = "all";
        }
        response.data.data[i]["recipe.name"] = ApiRecipes.getRecipeLabel(
          response.data.data[i][nameField],
          response.data.data[i]["recipe.allergens"],
          response.data.data[i]["recipe.macros"],
          response.data.data[i].txt_color
        );
        if (!this.menuItemChoices[mealType]) {
          this.menuItemChoices[mealType] = [];
        }
        this.menuItemChoices[mealType].push(response.data.data[i]);
      }
      let mealTypeChoice = this.getMealType();
      if (recipeType == "all") {
        mealTypeChoice = "all";
      }
      this.recipeChoices = formatDataToChoicesJs(
        this.menuItemChoices[mealTypeChoice],
        "no empty option",
        { id: "id", value: "id", label: "recipe.name" }
      );
    },
    async getRecipes(search_value) {
      let params =
        "?draw=10&columns[0][data]=id&columns[0][name]=id&columns[0][searchable]=true&columns[0][orderable]=true" +
        "&columns[0][search][value]=&columns[0][search][regex]=false" +
        "&columns[1][data]=name&columns[1][name]=name&columns[1][searchable]=true&columns[1][orderable]=true&columns[1][search][value]=" +
        "&columns[1][search][regex]=false&start=0&length=150&search[value]=" +
        search_value;

      return await ApiRecipes.dropdown(params).catch(handleError);
    },
    async setRecipe(id) {
      const response = await API.getRecipe(id, this.clientId).catch(
        handleError
      );
      if (response.status === 200) {
        this.recipe = response.data.data;
      } else {
        showMessage(response.message, "error");
      }
    },
    isClientAllergen(id, allergens) {
      return (
        allergens && allergens.length && allergens.find((el) => el.id == id)
      );
    },
    isClientFoodGroupDisliked(id, client_food_group_dislikes) {
      return (
        client_food_group_dislikes &&
        client_food_group_dislikes.length > 0 &&
        client_food_group_dislikes.find((el) => el.id == id)
      );
    },
    isDisliked(id, dislikes) {
      return (
        dislikes && dislikes.length > 0 && dislikes.find((el) => el.id == id)
      );
    },
    validateRemovedIngredients() {
      const validIngredientIds = this.recipeIngredients.map((ingredient) =>
        ingredient.id.toString()
      );
      this.removedIngredients = this.removedIngredients.filter((id) =>
        validIngredientIds.includes(id.toString())
      );
    },
    async addCustomizationsToFormData(formData) {
      this.validateRemovedIngredients();
      if (this.menuItem.customizations) {
        formData.set("customizations", this.menuItem.customizations);
      }
      for (let i in this.removedIngredients) {
        formData.set(
          "removed_ingredients[" + i + "]",
          this.removedIngredients[i]
        );
      }
    },
    async saveMenuCustomizations() {
      if (this.formSubmitted.menuCustomizations) {
        showMessage("Saving data. Please wait.", "");
        return;
      }
      this.formSubmitted.menuCustomizations = true;
      let formData = new FormData();
      this.addCustomizationsToFormData(formData);

      let response = await ApiClientDailyMenu.saveMenuCustomizations(
        this.clientId,
        this.delivery.id,
        this.menuItem.id,
        formData
      ).catch(handleError);

      if (response.status === 200) {
        showMessage("Menu item saved successfully.", "success");
        this.hideModal();
      } else {
        showMessage(response.message, "error");
      }
      this.$emit("saved");
      this.formSubmitted.menuCustomizations = false;
    },
    async saveSingleMenuItem() {
      if (this.formSubmitted.menu) {
        showMessage("Saving data. Please wait.", "");
        return;
      }
      if (!this.selectedMenuItem.id) {
        showMessage("Please select menu item.", "");
        return;
      }
      this.formSubmitted.menu = true;
      let recipe_id = this.selectedMenuItem.recipe_id;
      let menu_id = this.selectedMenuItem.id;
      let menu_type = this.selectedMenuItem.type;
      if (this.recipeType == "all") {
        recipe_id = this.selectedMenuItem.id;
        menu_id = this.selectedMenuItem.id;
        menu_type = "recipe";
      }

      let formData = new FormData();
      formData.append("meal_code", this.menuItemCode);
      formData.append("recipe_id", recipe_id);
      formData.append("menu_id", menu_id);
      formData.append("menu_type", menu_type);
      this.addCustomizationsToFormData(formData);
      let response = await ApiClientDailyMenu.saveSingleMenuItem(
        this.clientId,
        this.delivery.id,
        formData
      ).catch(handleError);

      if (response.status === 200) {
        showMessage("Menu item saved successfully.", "success");
        this.hideModal();
      } else {
        showMessage(response.message, "error");
      }

      this.$emit("saved");
      this.formSubmitted.menu = false;
    },
    hideModal() {
      let modal = this.$store.state.bootstrap.Modal.getInstance(
        document.getElementById("menu-modal")
      );
      modal.hide();
    },
    async resetRecipe() {
      this.menuItem.customizations = "";
      this.removedIngredients = [];
      this.selectedMenuItem = {};
      this.selectedMenuItemId = 0;
      this.recipe = {};
      await this.initRecipeChoices();
    },
    async handleRecipeTypeChange() {
      this.resetRecipe();
    },
    async showChangeRecipeForm() {
      this.resetRecipe();
      this.show.form = true;
      this.show.info = false;
    },
    async revertToCycle() {
      if (this.formSubmitted.revertToCycle) {
        showMessage("Saving data. Please wait.", "");
        return;
      }
      this.formSubmitted.revertToCycle = true;

      let response = await ApiClientDailyMenu.revertToCycleMenu(
        this.clientId,
        this.delivery.id,
        this.menuItem.id
      ).catch(handleError);

      if (response.status === 200) {
        showMessage(
          "Menu item reverted to cycle menu successfully.",
          "success"
        );
        this.hideModal();
      } else {
        showMessage(response.message, "error");
      }
      this.$emit("saved");
      this.formSubmitted.revertToCycle = false;
    },
  },
};
</script>
<style>
.text-disliked {
  color: #ffa500;
}
.img-contain {
  object-fit: contain;
  object-position: top;
}
</style>
