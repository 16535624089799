<template>
  <div class="container-fluid mt-4 invoice">
    <loading-spinner :loading="loading" />
    <div v-show="!loading">
      <div class="row">
        <div class="col-12 mx-auto col-xxl-12 col-xxxl-8">
          <div class="card my-sm-5">
            <div class="card-header">
              <div class="row justify-content-between">
                <div class="col-sm-6 text-start">
                  <img class="mb-2 logo p-2" :src="logo" alt="Logo" />
                </div>
                <div class="col-sm-6 text-end">
                  <div class="title">
                    {{
                      invoice.status !== "Paid"
                        ? "Proforma invoice"
                        : "Tax invoice"
                    }}
                  </div>
                  {{
                    dateFormat(invoice.created_at, {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })
                  }}
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-8 col-md-7 col-xxxl-5">
                  <table class="mt-5 w-100">
                    <tr>
                      <td><strong>Business Unit: </strong></td>
                      <td>Kcal Meal Plans</td>
                    </tr>
                    <tr>
                      <td><strong>Legal Entity Name: </strong></td>
                      <td>Kcal Healthy Catering LLC</td>
                    </tr>
                    <tr v-if="invoice.status == 'Paid'">
                      <td><strong>Tax Registration No.:</strong></td>
                      <td>100271929000003</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>
                          <template v-if="invoice.status != 'Paid'"
                            >Proforma Invoice No.:</template
                          ><template v-else>Invoice No.:</template>
                        </strong>
                      </td>
                      <td>{{ invoice.invoice_number }}</td>
                    </tr>
                  </table>
                  <h6 class="d-block mt-2 mb-0 name">
                    {{ invoice.first_name }} {{ invoice.last_name }}
                  </h6>
                  <div>
                    {{ invoice.address }}
                  </div>
                  <div v-if="invoice.vat_number">
                    TRN: {{ invoice.vat_number }}
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div class="table-responsive">
                    <table
                      v-if="hasInvoiceItems"
                      class="table text-right items mb-0"
                    >
                      <thead class="">
                        <tr class="header">
                          <th
                            scope="col"
                            class="px-2 text-start text-uppercase fw-bold"
                          >
                            Item
                          </th>
                          <th
                            scope="col"
                            class="px-2 text-center fw-bold text-uppercase"
                            width="100"
                          >
                            Quantity
                          </th>
                          <th
                            scope="col"
                            class="px-2 text-center fw-bold text-uppercase"
                            width="120"
                          >
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="invoice.id > 0">
                        <template
                          v-for="(
                            { description, quantity, value, document_type },
                            index
                          ) of invoiceLineItems"
                          :key="index"
                        >
                          <tr v-if="document_type != 'bag_deposit'">
                            <td class="px-2 text-start">
                              {{ description }}
                            </td>
                            <td class="px-2 text-center">
                              {{ quantity }}
                            </td>
                            <td class="px-2 text-center">
                              {{ currencyFormat(value) }}
                            </td>
                          </tr>
                        </template>
                        <tr>
                          <td colspan="2" class="px-2 text-end">
                            Sub Total (including VAT)
                          </td>
                          <td class="px-2 text-center">
                            {{ currencyFormat(invoice.net_amount) }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table
                      v-if="hasInvoiceItems"
                      class="table text-right items mb-0"
                    >
                      <thead>
                        <tr class="header">
                          <th
                            scope="col"
                            class="px-2 text-start text-uppercase fw-bold"
                          >
                            Breakdown
                          </th>
                          <th width="120"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="px-2 text-start">Amount Before VAT</td>
                          <td class="px-2 text-center">
                            {{
                              currencyFormat(invoice.net_amount - invoice.tax)
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="px-2 text-start">VAT 5% Amount</td>
                          <td class="px-2 text-center">
                            {{ currencyFormat(invoice.tax) }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table
                      v-if="hasBagDeposit"
                      class="table text-right items mb-0"
                    >
                      <thead class="">
                        <tr class="header">
                          <th
                            scope="col"
                            class="px-2 text-start text-uppercase fw-bold"
                          >
                            REFUNDABLE DEPOSIT
                          </th>
                          <th
                            scope="col"
                            class="px-2 text-center fw-bold text-uppercase"
                            width="120"
                          ></th>
                        </tr>
                      </thead>
                      <tbody v-if="invoice.id > 0">
                        <template
                          v-for="(
                            { value, document_type }, index
                          ) of invoiceLineItems"
                          :key="index"
                        >
                          <tr v-if="document_type == 'bag_deposit'">
                            <td class="px-2 text-start">
                              Chiller Bag (non taxable)
                            </td>
                            <td class="px-2 text-center">
                              {{ currencyFormat(value) }}
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                    <table class="table text-right items mt-5">
                      <thead class="">
                        <tr class="header">
                          <th class="text-end h5 ps-2 pe-2">
                            <span class="total"
                              >{{
                                invoice.status == "Paid"
                                  ? "Total received"
                                  : "Total amount due"
                              }}
                            </span>
                            <span class="amount">
                              {{ currencyFormat(invoice.amount) }}
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-end opacity-6">
                            Thank you for choosing Kcal Meal Plans
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div
                v-if="invoice.status === 'Draft'"
                class="row d-print-none mt-3"
              >
                <div v-show="!showDiscountForm" class="col-12">
                  <argon-button
                    class="btn-sm"
                    color="success"
                    variant="gradient"
                    type="button"
                    name="button"
                    @click="showDiscountForm = true"
                    >Discount
                  </argon-button>
                </div>
              </div>
              <div v-show="showDiscountForm" class="row mt-3 d-print-none">
                <div class="col-3">
                  <select
                    id="discount-code-category"
                    v-model="discountCodeCategory"
                  ></select>
                </div>
                <div class="col-5">
                  <select id="discount-code" v-model="discountCode"></select>
                </div>
                <div class="col">
                  <argon-button
                    color="success"
                    variant="gradient"
                    type="button"
                    name="button"
                    class="btn-xs"
                    @click="applyDiscount"
                    >Apply discount
                  </argon-button>
                  <argon-button
                    color="success"
                    variant="gradient"
                    type="button"
                    name="button"
                    class="ms-2 btn-xs"
                    @click="showDiscountForm = false"
                    >Close
                  </argon-button>
                </div>
              </div>
              <hr class="horizontal dark d-print-none" />
              <div v-if="canEditInvoice" class="row d-print-none mt-3">
                <div
                  v-show="!showAddonForm && addonChoices.length > 0"
                  class="col-12 mt-3"
                >
                  <argon-button
                    class="btn-sm"
                    color="info"
                    variant="gradient"
                    type="button"
                    name="button"
                    @click="showAddonForm = true"
                    >Addon
                  </argon-button>
                </div>
              </div>
              <div v-show="showAddonForm" class="row mt-3 d-print-none">
                <div class="col-6">
                  <select id="addon" v-model="addonId"></select>
                </div>
                <div class="col-auto">
                  <select
                    id="addon-quantity"
                    v-model="addonQuantity"
                    class="form-control"
                  >
                    <option v-for="(n, index) in 15" :key="index" :value="n">
                      {{ n }}
                    </option>
                  </select>
                </div>
                <div class="col-auto">
                  <argon-button
                    color="info"
                    variant="gradient"
                    type="button"
                    name="button"
                    class="btn-xs"
                    @click="addAddon"
                    >Add addon
                  </argon-button>
                  <argon-button
                    color="info"
                    variant="gradient"
                    type="button"
                    name="button"
                    class="ms-2 btn-xs"
                    @click="showAddonForm = false"
                    >Close
                  </argon-button>
                </div>
              </div>
            </div>
            <div class="card-footer pt-0">
              <div class="row d-none d-print-block mb-6 text-xs text-center">
                <div class="col-12 text-center">
                  <template v-if="invoice.status == 'Paid'">
                    <strong>Note:</strong>The chiller bag refundable deposit
                    will be reverted back to the original card once all
                    outstanding bags and ice packs have been collected.
                  </template>
                  <template v-else>
                    <strong>Note:</strong> Full payment for the renewal of your
                    plan must be made at least 3 business days prior to the
                    expiry of your current plan. If payment is not received
                    within this timeframe, this will affect the start date of
                    your new plan. See terms and conditions for more info.
                  </template>
                </div>
              </div>
              <div class="row d-none d-print-block">
                <div class="col-12 text-center">
                  Floor 15, X2 Tower, Cluster X, Jumeirah Lakes Towers, Dubai,
                  UAE, P.O. Box 391150.<br />
                  <a href="mailto:hello@kcallife.com">hello@kcallife.com</a> |
                  Tel: 800 39872 | www.kcallife.com/meal-plans/
                </div>
              </div>

              <div class="row d-print-none">
                <div class="col-lg-12 mt-3">
                  <ul class="list-group">
                    <li class="pt-0 text-sm border-0 list-group-item ps-0">
                      <strong class="text-dark">Client purchase type:</strong>
                      &nbsp;
                      {{ invoice.client_purchase_type }}
                    </li>
                    <li class="pt-0 text-sm border-0 list-group-item ps-0">
                      <strong class="text-dark">Initiator type:</strong> &nbsp;
                      {{ invoice.initiator_type }}
                    </li>
                    <li class="pt-0 text-sm border-0 list-group-item ps-0">
                      <strong class="text-dark">Invoice owner:</strong> &nbsp;
                      {{ invoice.owner_name }}
                    </li>
                    <li class="pt-0 text-sm border-0 list-group-item ps-0">
                      <strong class="text-dark">Invoice type:</strong> &nbsp;
                      {{ invoice.type }}
                    </li>
                    <li class="pt-0 text-sm border-0 list-group-item ps-0">
                      <strong class="text-dark">Invoice source:</strong> &nbsp;
                      {{ invoice.source }}
                    </li>
                    <li class="pt-0 text-sm border-0 list-group-item ps-0">
                      <strong class="text-dark">Invoice status:</strong> &nbsp;
                      {{ invoice.status }}
                    </li>
                    <li class="pt-0 text-sm border-0 list-group-item ps-0">
                      <strong class="text-dark">UTM source:</strong> &nbsp;
                      {{ invoice.utm_source }}
                    </li>
                    <li class="pt-0 text-sm border-0 list-group-item ps-0">
                      <strong class="text-dark">UTM campaign:</strong> &nbsp;
                      {{ invoice.utm_campaign }}
                    </li>
                    <li class="pt-0 text-sm border-0 list-group-item ps-0">
                      <strong class="text-dark">Payment source:</strong> &nbsp;
                      {{ invoice.payment_source }}
                    </li>
                    <li class="pt-0 text-sm border-0 list-group-item ps-0">
                      <strong class="text-dark">Payment status:</strong> &nbsp;
                      {{ invoice.payment_status }}
                    </li>
                    <li class="pt-0 text-sm border-0 list-group-item ps-0">
                      <strong class="text-dark">Payment date:</strong> &nbsp;
                      {{
                        invoice.payment_status === "Success"
                          ? dateFormat(invoice.payment_time)
                          : invoice.payment_status
                      }}
                    </li>
                    <li
                      v-if="
                        invoice.type != 'Credit Note' &&
                        invoice.status == 'Published'
                      "
                      class="pt-0 text-sm border-0 list-group-item ps-0"
                    >
                      <strong class="text-dark">Payment link:</strong> &nbsp;
                      <popper-copy-text
                        :text="invoice.payment_link"
                        copied-text="Payment link copied"
                        >{{ invoice.payment_link }}</popper-copy-text
                      >
                    </li>
                    <li class="pt-0 text-sm border-0 list-group-item ps-0">
                      <strong class="text-dark">Invoice notes:</strong> &nbsp;
                      {{ invoice.notes }}
                    </li>
                  </ul>
                </div>
                <div
                  v-if="invoice.id > 0 && invoicePayments.length > 0"
                  class="col-lg-12 mt-3 d-print-none"
                >
                  <h5>Transactions</h5>
                  <div class="table-responsive border-radius-lg">
                    <table class="table text-right">
                      <thead class="bg-default text-white">
                        <tr>
                          <th scope="col" class="pe-2">#</th>
                          <th scope="col" class="pe-2">Payment time</th>
                          <th scope="col" class="pe-2">Description</th>
                          <th scope="col" class="pe-2">Payment source</th>
                          <th scope="col" class="pe-2">Reference</th>
                          <th scope="col" class="pe-2">Amount</th>
                          <th scope="col" class="pe-2">Status</th>
                        </tr>
                      </thead>
                      <tbody v-if="invoice.id > 0">
                        <tr
                          v-for="(
                            {
                              payment_time,
                              description,
                              payment_source,
                              reference,
                              amount,
                              payment_status,
                            },
                            index
                          ) of invoicePayments"
                          :key="index"
                        >
                          <td class="ps-4">{{ index + 1 }}</td>
                          <td class="ps-4">{{ dateFormat(payment_time) }}</td>
                          <td class="ps-4">{{ description }}</td>
                          <td class="ps-4">{{ payment_source }}</td>
                          <td class="ps-4">{{ reference }}</td>
                          <td class="ps-4">{{ currencyFormat(amount) }}</td>
                          <td class="ps-4">{{ payment_status }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  class="col-lg-12 text-end d-print-none d-grid d-md-block gap-3"
                >
                  <argon-button
                    v-if="canEditInvoice"
                    color="success"
                    variant="gradient"
                    type="button"
                    name="button"
                    class="mt-md-3"
                    @click="setInvoiceStatusPublish"
                    >Publish invoice
                  </argon-button>
                  <argon-button
                    v-if="hasLoyalty && canEditInvoice"
                    color="success"
                    variant="gradient"
                    type="button"
                    name="button"
                    class="mt-md-3 ms-md-3"
                    @click="removeLoyaltyDiscount"
                    >Remove loyalty discount
                  </argon-button>
                  <argon-button
                    v-if="canEditInvoice"
                    color="success"
                    variant="gradient"
                    type="button"
                    name="button"
                    class="ms-md-3 mt-md-3"
                    @click="resetInvoice"
                    >Reset invoice
                  </argon-button>
                  <argon-button
                    v-if="
                      invoice.status === 'Published' &&
                      $can('create', 'payments')
                    "
                    color="success"
                    variant="gradient"
                    type="button"
                    name="button"
                    class="ms-md-3 mt-md-3"
                    data-bs-toggle="modal"
                    data-bs-target="#manual-payment-modal"
                    >Manual Payment
                  </argon-button>
                  <argon-button
                    v-if="
                      invoice.status === 'Published' &&
                      $can('create', 'payments')
                    "
                    color="success"
                    variant="gradient"
                    type="button"
                    name="button"
                    class="ms-md-3 mt-md-3"
                    @click="showModalObject(walletPaymentModal)"
                    >Wallet Payment
                  </argon-button>
                  <argon-button
                    v-if="canUnPublish"
                    color="success"
                    variant="gradient"
                    type="button"
                    name="button"
                    class="ms-md-3 mt-md-3"
                    @click="setInvoiceStatusUnpublish"
                    >Unpublish invoice
                  </argon-button>
                  <argon-button
                    v-if="showCancelInvoiceButton"
                    color="danger"
                    variant="gradient"
                    type="button"
                    name="button"
                    class="ms-md-3 mt-md-3"
                    @click="setInvoiceStatusCancel"
                    >Cancel invoice
                  </argon-button>
                  <argon-button
                    v-if="
                      invoice.status === 'Paid' &&
                      $can('create', 'invoice_refund_requests') &&
                      invoice.type != 'Credit Note'
                    "
                    color="success"
                    variant="gradient"
                    type="button"
                    name="button"
                    class="ms-md-3 mt-md-3"
                    @click="showRefundRequestModal"
                    >Refund request
                  </argon-button>
                  <router-link
                    v-if="
                      invoice.client_id > 0 && invoice.client_plan_detail_id > 0
                    "
                    class="btn mb-0 bg-gradient-success btn-md ms-md-3 mt-md-3"
                    :to="{
                      name: 'Client Plan',
                      params: {
                        id: invoice.client_id,
                        planId: invoice.client_plan_detail_id,
                      },
                    }"
                    >View plan
                  </router-link>
                  <router-link
                    v-if="invoice.client_id > 0"
                    class="btn mb-0 bg-gradient-success btn-md ms-md-3 mt-md-3"
                    :to="{
                      name: 'Client Profile',
                      params: {
                        id: invoice.client_id,
                      },
                    }"
                    >View client
                  </router-link>
                  <argon-button
                    v-if="canEditInvoice"
                    color="success"
                    variant="gradient"
                    type="button"
                    name="button"
                    class="ms-md-3 mt-md-3"
                    @click="showModalObject(attachMediaModal)"
                    >Attach Media
                  </argon-button>
                  <argon-button
                    class="ms-md-3 mt-md-3"
                    color="success"
                    variant="gradient"
                    onclick="window.print()"
                    type="button"
                    name="button"
                    >Print
                  </argon-button>
                  <router-link
                    class="btn mb-0 bg-gradient-secondary btn-md ms-md-3 mt-md-3"
                    :to="{ name: 'Invoices List' }"
                    >Back
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <manual-payment-modal
        modal-id="manual-payment-modal"
        dialog-class="modal-lg"
        :invoice-id="invoice.id"
        @saved="setInvoice"
        @close="closeModal('manual-payment-modal')"
      />
      <wallet-payment-modal
        v-if="walletPaymentModal.show"
        :modal-id="walletPaymentModal.id"
        dialog-class="modal-lg"
        :invoice-id="invoice.id"
        @saved="setInvoice"
        @close="closeModalObject(walletPaymentModal)"
      />
      <refund-request-modal
        v-if="currentRefundRequest.show"
        modal-id="invoice-refund-request-modal"
        :invoice-id="invoice.id"
        :invoice-payment-source="invoice.payment_source"
        :initial-data="currentRefundRequest"
        dialog-class="modal-xl"
        @saved="setInvoice"
        @close="closeModal('invoice-refund-request-modal')"
      />
      <attach-media-modal
        v-if="attachMediaModal.show"
        :modal-id="attachMediaModal.id"
        dialog-class="modal-lg"
        :invoice-id="invoice.id"
        @saved="setInvoice"
        @close="closeModalObject(attachMediaModal)"
      />
    </div>
  </div>
</template>

<script>
import logo from "@/assets/img/kcal-logo.svg";
import ArgonButton from "@/components/ArgonButton.vue";
import API from "@/services/api";
import { showMessage } from "@/assets/js/show-message";
import { formatDataToChoicesJs, initChoices } from "@/assets/js/init-choices";
import { handleError, currencyFormat } from "@/lib/helpers";
import { dateFormat } from "@/lib/dateHelper";
import ApiDiscounts from "@/services/apiDiscounts";
import ApiInvoices from "@/services/apiInvoices";
import ApiAddons from "@/services/apiAddons";
import apiMiscList from "@/services/apiMiscList";
import RefundRequestModal from "./components/RefundRequestModal";
import ManualPaymentModal from "./components/ManualPaymentModal.vue";
import WalletPaymentModal from "./components/WalletPaymentModal.vue";
import AttachMediaModal from "@/views/pages/Invoices/components/AttachMediaModal.vue";
import {
  showModal,
  closeModal,
  closeModalObject,
  showModalObject,
} from "@/lib/bootstrap";
import { mapGetters } from "vuex";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import constants from "@/constants";
import PopperCopyText from "@/components/PopperCopyText.vue";

export default {
  name: "Invoice",
  components: {
    ArgonButton,
    RefundRequestModal,
    ManualPaymentModal,
    WalletPaymentModal,
    LoadingSpinner,
    PopperCopyText,
    AttachMediaModal,
  },
  data() {
    return {
      logo,
      loading: true,
      invoice: {
        id: 0,
      },
      showDiscountForm: false,
      showAddonForm: false,
      discountCode: "",
      discountCodeCategory: "Marketing",
      addonId: 0,
      addonQuantity: 1,
      discountCodeChoices: [],
      discountCodeCategoryChoices: [],
      addonChoices: [],

      currentRefundRequest: { show: false },
      refundRequestFormSubmitted: false,
      walletPaymentModal: { show: false, id: "wallet-payment-modal" },
      attachMediaModal: { show: false, id: "attach-media-modal" },
    };
  },
  computed: {
    invoiceLineItems() {
      return this.invoice.invoiceLineItems && this.invoice.invoiceLineItems.data
        ? this.invoice.invoiceLineItems.data
        : [];
    },
    invoicePayments() {
      return this.invoice.payments.data ? this.invoice.payments.data : [];
    },
    invoiceRefundRequests() {
      return this.invoice.invoiceRefundRequests.data
        ? this.invoice.invoiceRefundRequests.data
        : [];
    },
    hasBagDeposit() {
      return this.invoiceLineItems.some(
        (item) => item.document_type === "bag_deposit"
      );
    },
    hasLoyalty() {
      return this.invoiceLineItems.some(
        (item) => item.document_type === "loyalty_tier"
      );
    },
    hasInvoiceItems() {
      return this.invoiceLineItems.some(
        (item) => item.document_type !== "bag_deposit"
      );
    },
    showCancelInvoiceButton() {
      const isNotPaidOrCancelled =
        this.invoice.status !== "Paid" && this.invoice.status !== "Cancelled";

      const canCancel =
        this.isSuperAdmin ||
        this.isAccountSupervisor ||
        this.isAccountManagerOfInvoice ||
        this.isSalesAgentOfInvoice;

      return isNotPaidOrCancelled && canCancel;
    },
    isAccountSupervisor() {
      return this.hasRole(constants.ROLES.ACCOUNT_SUPERVISOR);
    },

    isSuperAdmin() {
      return this.hasRole(constants.ROLES.SUPER_ADMIN);
    },
    isAccountManagerOfInvoice() {
      return (
        this.hasRole(constants.ROLES.ACCOUNT_MANAGER) &&
        this.invoice.user_id === this.getCurrentUserId
      );
    },
    isSalesAgentOfInvoice() {
      return (
        this.hasRole(constants.ROLES.SALES_AGENT) &&
        this.invoice.user_id === this.getCurrentUserId
      );
    },
    canUnPublish() {
      return (
        this.invoice.status === "Published" &&
        (this.isSuperAdmin ||
          this.isAccountSupervisor ||
          this.isAccountManagerOfInvoice ||
          this.isSalesAgentOfInvoice)
      );
    },
    canEditInvoice() {
      return this.invoice.status === "Draft";
    },

    ...mapGetters({
      hasRole: "auth/hasRole",
      getCurrentUserId: "auth/getCurrentUserId",
    }),
  },
  async mounted() {
    await this.init();
  },
  methods: {
    showModalObject,
    closeModalObject,
    dateFormat,
    closeModal,
    currencyFormat,
    async init() {
      await this.setInvoice();
      if (this.canEditInvoice) {
        await this.setDiscountCodeCategoryChoices();
        await this.initDiscountCodeCategoryChoices();
        await this.setDiscountCodeChoices();
        await this.initDiscountCodeChoices();
        await this.setAddonChoices();
        await this.initAddonChoices();
        this.loading = false;
      }
    },
    async setInvoice() {
      this.loading = true;
      this.invoice = {};
      const appInstance = this;
      await ApiInvoices.get(appInstance.$route.params.id)
        .then((res) => {
          this.invoice = res.data.data;
        })
        .catch((err) => {
          if (err.response.data.status === 404) {
            this.$swal({
              icon: "error",
              title: "Invoice not found",
              willClose: () => {
                appInstance.$router.push({ name: "Invoices List" });
              },
            });
          } else {
            const response = API.handleError(err);
            showMessage(response.message, "error");
          }
        });
      this.loading = false;
    },
    async applyDiscount() {
      if (this.discountCode.length > 0) {
        if (this.formSubmitted) {
          showMessage("Saving data. Please wait.", "", 1500);
          return;
        }
        this.formSubmitted = true;
        let formData = new FormData();
        formData.set("discount_code", this.discountCode);
        let response = await ApiInvoices.applyDiscountCode(
          this.invoice.id,
          formData
        ).catch(handleError);

        if (response.status === 200) {
          await this.setInvoice();
          showMessage("Discount code applied successfully.", "success");
        } else {
          showMessage(response.message, "error");
        }
        this.formSubmitted = false;
      } else {
        showMessage("Please select a discount.", "error");
      }
    },
    async resetInvoice() {
      if (this.formSubmitted) {
        showMessage("Saving data. Please wait.", "", 1500);
        return;
      }
      this.formSubmitted = true;
      let response = await ApiInvoices.reset(this.invoice.id).catch(
        handleError
      );

      if (response.status === 200) {
        await this.init();
        showMessage("Invoice reset successfully.", "success");
      } else {
        showMessage(response.message, "error");
      }
      this.formSubmitted = false;
    },
    async removeLoyaltyDiscount() {
      if (this.formSubmitted) {
        showMessage("Saving data. Please wait.", "", 1500);
        return;
      }
      this.formSubmitted = true;
      let response = await ApiInvoices.removeLoyaltyDiscount(
        this.invoice.id
      ).catch(handleError);

      if (response.status === 200) {
        await this.setInvoice();
        showMessage("Loyalty discount removed successfully.", "success");
      } else {
        showMessage(response.message, "error");
      }
      this.formSubmitted = false;
    },
    async setDiscountCodeCategoryChoices() {
      if (this.discountCodeCategoryChoices.length > 0) {
        return;
      }
      let response = await apiMiscList
        .discountCodeCategory()
        .catch(handleError);
      if (response.status === 200) {
        this.discountCodeCategoryChoices = formatDataToChoicesJs(
          response.data.data,
          [],
          { id: "value", value: "value", label: "value" }
        );
      } else {
        showMessage(response.message, "error", 5000);
      }
    },
    async initDiscountCodeCategoryChoices() {
      const appInstance = this;
      const id = "discount-code-category";
      const element = document.getElementById(id);
      await initChoices(
        id,
        {
          choices: this.discountCodeCategoryChoices,
        },
        this.discountCodeCategory
      );
      element.addEventListener(
        "change",
        async function () {
          appInstance.discountCodeChoices = [];
          await appInstance.setDiscountCodeChoices();
          await appInstance.initDiscountCodeChoices();
        },
        false
      );
    },
    async setDiscountCodeChoices(search) {
      if (this.discountCodeChoices.length > 0) {
        return;
      }
      let url =
        "start=0&length=20&filter[category]=" + this.discountCodeCategory;
      if (search) {
        url =
          "start=0&length=20&filter[category]=" +
          this.discountCodeCategory +
          "&filter[name]=" +
          search;
      }
      let response = await ApiDiscounts.get(url).catch(handleError);
      if (response.status === 200) {
        this.discountCodeChoices = formatDataToChoicesJs(
          response.data.data,
          "",
          {
            id: "id",
            value: "code",
            label: "name",
          }
        );
      } else {
        showMessage(response.message, "error", 5000);
      }
    },
    async setAddonChoices() {
      if (this.addonChoices.length > 0) {
        return;
      }
      let response = await ApiAddons.get("start=0&length=-1").catch(
        handleError
      );
      if (response.status === 200) {
        this.addonChoices = formatDataToChoicesJs(response.data.data, "", {
          id: "id",
          value: "id",
          label: "name",
        });
      } else {
        showMessage(response.message, "error", 5000);
      }
    },
    async initDiscountCodeChoices() {
      const appInstance = this;
      const id = "discount-code";
      const element = document.getElementById(id);
      await initChoices(id, {
        choices: this.discountCodeChoices,
      });
      let myTimeOut = null;
      element.addEventListener(
        "search",
        async function (event) {
          clearTimeout(myTimeOut);
          myTimeOut = setTimeout(async () => {
            appInstance.discountCodeChoices = [];
            await appInstance.setDiscountCodeChoices(event.detail.value);
            await initChoices(id, {
              choices: appInstance.discountCodeChoices,
            });
          }, 500);
        },
        false
      );
    },
    async initAddonChoices() {
      await initChoices("addon", {
        choices: this.addonChoices,
      });
    },

    async setInvoiceStatusPublish() {
      if (this.formSubmitted) {
        showMessage("Saving data. Please wait.", "", 1500);
        return;
      }
      this.$swal({
        title: "Are you sure?",
        text: "Invoices cannot be changed once published. Please confirm you have checked and verified the plan and invoice details",
        showCancelButton: true,
        confirmButtonText: "Yes, publish the invoice!",
        cancelButtonText: "No, I have to check the details!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-danger ms-2",
          cancelButton: "btn bg-gradient-success",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.formSubmitted = true;
          let response = await ApiInvoices.setStatus(
            this.invoice.id,
            "publish"
          ).catch(handleError);
          if (response.status === 200) {
            await this.setInvoice();
            showMessage("Invoice published successfully.", "success");
          } else {
            showMessage(response.message, "error");
          }
          this.formSubmitted = false;
        }
      });
    },
    async setInvoiceStatusUnpublish() {
      if (this.formSubmitted) {
        showMessage("Saving data. Please wait.", "", 1500);
        return;
      }
      this.formSubmitted = true;
      let response = await ApiInvoices.setStatus(
        this.invoice.id,
        "unpublish"
      ).catch(handleError);
      if (response.status === 200) {
        await this.init();
        showMessage("Invoice unpublished successfully.", "success");
      } else {
        showMessage(response.message, "error");
      }
      this.formSubmitted = false;
    },
    async setInvoiceStatusCancel() {
      if (this.formSubmitted) {
        showMessage("Saving data. Please wait.", "", 1500);
        return;
      }
      this.$swal({
        title: "Are you sure?",
        text: "Invoices cannot be changed once cancelled. Please confirm you want to cancel the invoice.",
        showCancelButton: true,
        confirmButtonText: "Yes, cancel the invoice!",
        cancelButtonText: "No, I have changed my mind!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-danger ms-2",
          cancelButton: "btn bg-gradient-success",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.formSubmitted = true;
          let response = await ApiInvoices.setStatus(
            this.invoice.id,
            "cancel"
          ).catch(handleError);
          if (response.status === 200) {
            await this.setInvoice();
            showMessage("Invoice cancelled successfully.", "success");
          } else {
            showMessage(response.message, "error");
          }
          this.formSubmitted = false;
        }
      });
    },
    async addAddon() {
      if (this.addonId > 0) {
        if (this.formSubmitted) {
          showMessage("Saving data. Please wait.", "", 1500);
          return;
        }
        this.formSubmitted = true;
        let formData = new FormData();
        formData.set("addon_id", this.addonId);
        formData.set("quantity", this.addonQuantity);
        let response = await ApiInvoices.includeAddon(
          this.invoice.id,
          formData
        ).catch(handleError);

        if (response.status === 200) {
          await this.setInvoice();
          showMessage("Addon added successfully.", "success");
        } else {
          showMessage(response.message, "error");
        }
        this.formSubmitted = false;
      } else {
        showMessage("Please select a discount.", "error");
      }
    },
    async showRefundRequestModal(refundRequestId) {
      const item = this.invoiceRefundRequests.find(
        (el) => el.id == refundRequestId
      );
      this.currentRefundRequest = { show: false };
      await this.$nextTick();
      if (item) {
        this.currentRefundRequest = item;
      }
      this.currentRefundRequest.show = true;
      await this.$nextTick();
      showModal("invoice-refund-request-modal");
    },
  },
};
</script>
<style>
.invoice .row .card {
  font-size: 13px;
}
.invoice .row .col-invoice {
  max-width: 66%;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.invoice .logo {
  width: 140px;
}
.invoice .title {
  font-size: 40px;
}
.invoice .name {
  font-size: 22px;
}
.invoice .items .header {
  background: #f2f2f2;
}
.invoice .items header::before {
  content: "";
  display: block;
  height: 3rem;
}
.invoice .items .total {
  font-size: 18px;
}
.invoice .items .amount {
  font-size: 28px;
  margin-left: 10px;
}
.invoice .table thead th,
.invoice .table td,
.invoice .table > :not(caption) > * > * {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
}
.invoice .table > :not(caption) > * > * {
  border: 0 none;
  box-shadow: none;
}
.invoice .table > :not(:first-child) {
  border: 0 none;
}
@media print {
  body.bg-gray-100 {
    background: #ffffff !important;
  }
  .sidenav.fixed-start + .main-content {
    margin-left: 0 !important;
    background: #ffffff;
  }
  .invoice .row .col-invoice {
    max-width: none;
  }
  .invoice.container-fluid {
    margin: 0 !important;
    padding: 0 !important;
  }
  .invoice .card.my-sm-5 {
    box-shadow: none;
    margin: 0 !important;
    border-radius: 0 !important;
    border: 0 none !important;
  }
}
</style>
