<template>
  <div class="mb-4 card">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex">
        <h6 class="mb-1">Consultations</h6>
        <div class="text-end ms-auto">
          <router-link
            v-if="$can('create', 'consultations')"
            class="mb-0 btn btn-xs bg-gradient-success"
            :to="{
              name: 'New Consultation',
            }"
          >
            <i class="fas fa-plus pe-2" aria-hidden="true"></i> New Consultation
          </router-link>
        </div>
      </div>
    </div>
    <div class="p-3 card-body">
      <div class="table-responsive">
        <table class="table align-items-center mb-0 hover">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Consultation date
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Nutritionist
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Goal
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Mode
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Result Status
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(consultation, index) of consultations" :key="index">
              <td class="">
                <p class="text-sm text-secondary mb-0">
                  {{ dateFormat(consultation.consultation_date) }}
                </p>
              </td>
              <td class="">
                <p class="text-sm text-secondary mb-0">
                  {{ getNutritionistName(consultation.nutritionist_id) }}
                </p>
              </td>
              <td class="">
                <p class="text-sm text-secondary mb-0">
                  {{ consultation.goal }}
                </p>
              </td>
              <td class="">
                <p class="text-sm text-secondary mb-0">
                  {{ consultation.mode }}
                </p>
              </td>
              <td class="">
                <p class="text-sm text-secondary mb-0">
                  {{ consultation.result_status }}
                </p>
              </td>
              <td class="align-middle text-center text-sm">
                <router-link
                  v-if="$can('view', 'consultations')"
                  :to="{
                    name: 'Consultations View',
                    params: {
                      id: clientId,
                      consultationId: consultation.id,
                    },
                  }"
                >
                  <i class="fas fa-eye text-secondary" aria-hidden="true"></i>
                </router-link>

                <router-link
                  v-if="$can('update', 'consultations')"
                  :to="{
                    name: 'Edit Consultation',
                    params: {
                      id: clientId,
                      consultationId: consultation.id,
                    },
                  }"
                >
                  <i
                    class="far fa-edit text-secondary mx-3"
                    aria-hidden="true"
                  ></i>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat } from "@/lib/dateHelper";
import API from "@/services/api";
import { handleError } from "@/lib/helpers";
import { showMessage } from "@/assets/js/show-message";
export default {
  name: "ConsultationsCard",
  props: {
    clientId: {
      type: [String, Number],
      required: true,
    },
    consultations: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["setConsultations"],
  data() {
    return {
      nutritionists: [],
    };
  },
  async mounted() {
    await this.setNutritionists();
  },
  methods: {
    dateFormat,
    async setNutritionists() {
      const response = await API.getNutritionists().catch(handleError);
      if (response.status == 200) {
        this.nutritionists = response.data.data;
      } else {
        showMessage(response.message, "error");
      }
    },
    getNutritionistName(id) {
      const nutrititionist = this.nutritionists.find(
        (nutritionist) => nutritionist.id === id
      );
      if (nutrititionist) {
        return nutrititionist.name;
      }
      return `Nutritionist #${id}`;
    },
  },
};
</script>
