import API from "@/services/api";
import apiProfile from "@/services/apiProfile";
import CONSTANTS from "@/constants";
import ability from "@/services/ability";
const roleDashboardAbilities = {
  [CONSTANTS.ROLES.SUPER_ADMIN]: {
    action: "view",
    subject: "super_admin_dashboard",
    path: "/dashboard",
  },
  [CONSTANTS.ROLES.ACCOUNT_ADMIN]: {
    action: "view",
    subject: "account_admin_dashboard",
    path: "/dashboard-account-admin",
  },
  [CONSTANTS.ROLES.ACCOUNT_MANAGER]: {
    action: "view",
    subject: "account_manager_dashboard",
    path: "/dashboard-account-manager",
  },
  [CONSTANTS.ROLES.ACCOUNT_SUPERVISOR]: {
    action: "view",
    subject: "account_supervisor_dashboard",
    path: "/dashboard-account-supervisor",
  },
  [CONSTANTS.ROLES.SALES_AGENT]: {
    action: "view",
    subject: "sales_agent_dashboard",
    path: "/dashboard-sales-agent",
  },
  [CONSTANTS.ROLES.NUTRITIONIST]: {
    action: "view",
    subject: "nutritionist_dashboard",
    path: "/dashboard-nutritionist",
  },
  [CONSTANTS.ROLES.SALES_MANAGER]: {
    action: "view",
    subject: "sales_manager_dashboard",
    path: "/dashboard-sales-manager",
  },
};

export default {
  namespaced: true,
  state: {
    authenticated: false,
    user: {},
    current_role: "",
  },
  getters: {
    getCurrentRole(state) {
      return state.current_role;
    },
    getUserRoles(state) {
      return state.user.roles.data;
    },
    getCurrentUserId(state) {
      return state.user.id;
    },
    hasRole: (state) => (role) => {
      const userRoles = state.user.roles.data.map((role) => role.id);
      return userRoles.includes(role);
    },
  },
  mutations: {
    setAuthenticated(state, value) {
      state.authenticated = value;
    },
    setUser(state, value) {
      state.user = value;
    },
    setCurrentRole(state, value) {
      state.current_role = value;
    },
  },
  actions: {
    login({ commit }) {
      commit("setAuthenticated", true);
    },
    logout({ commit }) {
      commit("setAuthenticated", false);
      commit("setUser", {});
      commit("setCurrentRole", "");
    },
    async setUser({ commit, dispatch }) {
      return apiProfile
        .get()
        .then(async ({ data }) => {
          const updatedUser = await dispatch("setUserAbilities", {
            userData: data.data,
          });
          commit("setUser", updatedUser);
          commit("setCurrentRole", data.data.roles.data[0].id);
        })
        .catch(() => {
          commit("setAuthenticated", false);
          commit("setUser", {});
        })
        .finally(() => {});
    },
    setUserAbilities(context, { userData }) {
      let abilities = [];
      if (userData.roles && userData.roles.data) {
        const hasSuperAdminRole = userData.roles.data.some(
          (role) => role.id === CONSTANTS.ROLES.SUPER_ADMIN
        );

        if (hasSuperAdminRole) {
          abilities = Object.values(roleDashboardAbilities);
        } else {
          userData.roles.data.forEach((role) => {
            const dashboardAbility = roleDashboardAbilities[role.id];
            if (dashboardAbility) {
              abilities.push(dashboardAbility);
            }
          });
        }
      }
      if (userData.permissions.data) {
        abilities = abilities.concat(
          API.formatUserPermissionsToCaslAbilities(userData.permissions.data)
        );
      }
      userData.abilities = abilities;
      return userData;
    },
    redirectToAvailableDashboard({ state }, router) {
      const currentPath = router.currentRoute.value.path;

      let redirectPath = null;

      for (const role of state.user.roles.data) {
        const dashboardAbility = roleDashboardAbilities[role.id];

        if (dashboardAbility && ability.can("view", dashboardAbility.subject)) {
          if (currentPath === dashboardAbility.path) {
            return false;
          }
          if (!redirectPath) {
            redirectPath = dashboardAbility.path;
          }
        }
      }

      if (redirectPath) {
        router.push({ path: redirectPath });
        return true;
      }

      router.push({ path: "/dashboard-default" });
      return false;
    },
  },
};
